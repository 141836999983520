<template>
    <div class="common-layout">
        <el-container>
            <el-aside class="el-aside">
                <Sidebar class="aside" />
            </el-aside>
            <el-container>
                <el-header>
                    <Header />
                </el-header>
                <el-main>
                    <MainContent />
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Header from "./pages/Header.vue";
import Sidebar from "./pages/Aside.vue";
import MainContent from "./pages/MainContent.vue";

export default defineComponent({
    components: {
        Header,
        Sidebar,
        MainContent,
    },
});
</script>

<style scoped>
.common-layout {
    height: 98.3vh;
    display: flex;
    flex-direction: column; /* 使内容垂直排列 */
}
.header {
    padding: 0;
    margin-bottom: 20px; /* 确保header与aside之间有间距 */
}
.el-container {
    flex: 1; /* 使el-container占据剩余空间 */
    display: flex; /* 使子元素横向排列 */
}
.aside {
    box-sizing: border-box;
    display: flex;
    flex-direction: column; /* 确保侧边栏中的内容垂直排列 */
    height: 100%; /* 使aside填满el-container的高度 */
    position: absolute;
}
.el-aside {
    box-sizing: border-box;
    flex-shrink: 0;
    overflow: auto;
    width: auto;
}
.el-header {
    --el-header-padding: 0 0px;
    --el-header-height: 60px;
    box-sizing: border-box;
    flex-shrink: 0;
    height: auto;
    padding: auto;
}
.main-content {
    flex: 1; /* 使MainContent占据剩余空间 */
    padding: 20px; /* 添加内边距 */
}
</style>
