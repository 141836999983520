import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home/home.vue";
import UserLogin from "../components/UserLogin.vue";
import Personal from "../views/User/personal.vue";
import Usersetting from "../views/User/usersetting.vue";
import Userlist from "../views/Userlist/userlist.vue";
import Adduser from "../views/Userlist/adduser.vue";
import Announcement from "../views/Announcement/announcement.vue";
import AddAnnouncement from "../views/Announcement/addannouncement.vue";
import Adddish from "@/views/Dish/adddish.vue";
import Dish from "@/views/Dish/dish.vue";

const routes = [
    { path: "/", name: "home", component: Home, meta: { requiresAuth: true } }, // 需要身份验证
    { path: "/UserLogin", component: UserLogin },
    { path: "/personal", component: Personal, meta: { requiresAuth: true } }, // 需要身份验证
    {
        path: "/usersetting",
        component: Usersetting,
        meta: { requiresAuth: true },
    }, // 需要身份验证
    { path: "/userlist", component: Userlist, meta: { requiresAuth: true } }, // 需要身份验证
    { path: "/adduser", component: Adduser, meta: { requiresAuth: true } }, // 需要身份验证
    {
        path: "/announcement",
        component: Announcement,
        meta: { requiresAuth: true },
    }, // 需要身份验证
    {
        path: "/addannouncement",
        component: AddAnnouncement,
        meta: { requiresAuth: true },
    }, // 需要身份验证
    {
        path: "/Adddish",
        component: Adddish,
        meta: { requiresAuth: true },
    },
    {
        path: "/Dish",
        component: Dish,
        meta: { requiresAuth: true },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const token = sessionStorage.getItem("token");

    // 如果目标路由是 /UserLogin，清除 token
    if (to.path === "/UserLogin") {
        sessionStorage.removeItem("token");
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!token) {
            next({
                path: "/UserLogin",
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
