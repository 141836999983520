vue
<template>
    <div>
        <!-- 搜索功能 -->
        <div style="margin-bottom: 20px">
            <el-input
                v-model="searchQuery"
                placeholder="输入公告ID或标题搜索"
                style="width: 300px; margin-right: 10px"
            ></el-input>
            <el-button
                type="primary"
                @click="searchAnnouncements"
                :icon="Search"
                >搜索</el-button
            >
        </div>
        <el-table
            :data="filteredAnnouncements"
            border
            style="width: 100%"
            :header-cell-style="{ backgroundColor: '#f2f2f2', color: '#333' }"
        >
            <el-table-column label="公告ID" width="180">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span style="margin-left: 10px">{{
                            scope.row.id
                        }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="公告标题" width="634">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span>《 {{ scope.row.title }} 》</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="状态" width="200">
                <template #default="scope">
                    <el-switch
                        v-model="scope.row.status"
                        :active-value="'show'"
                        :inactive-value="'hide'"
                        @change="(newStatus: string) => updateStatus(scope.row.id, newStatus)"
                        style="
                            --el-switch-on-color: #13ce66;
                            --el-switch-off-color: #ff4949;
                        "
                    />
                    <span style="margin-left: 10px">{{
                        scope.row.status === "show" ? "显示" : "隐藏"
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" width="220">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <el-icon><timer /></el-icon>
                        <span>{{ formatDate(scope.row.createdAt) }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="创建用户" width="180">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span>{{ scope.row.creator.nickname }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="300">
                <template #default="scope">
                    <el-button size="small" @click="openEditDialog(scope.row)"
                        >编辑</el-button
                    >
                    <el-button
                        size="small"
                        type="danger"
                        @click="confirmDelete(scope.row.id)"
                        >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <!-- 编辑公告对话框 -->
        <el-dialog title="编辑公告" v-model="editDialogVisible" width="30%">
            <el-form :model="editForm" ref="editFormRef" label-width="100px">
                <el-form-item
                    label="公告标题"
                    prop="title"
                    :rules="[
                        {
                            required: true,
                            message: '请输入公告标题',
                            trigger: 'blur',
                        },
                    ]"
                >
                    <el-input
                        v-model="editForm.title"
                        placeholder="请输入公告标题"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="公告内容"
                    prop="content"
                    :rules="[
                        {
                            required: true,
                            message: '请输入公告内容',
                            trigger: 'blur',
                        },
                    ]"
                >
                    <el-input
                        type="textarea"
                        v-model="editForm.content"
                        placeholder="请输入公告内容"
                        :rows="4"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="状态"
                    prop="status"
                    :rules="[
                        {
                            required: true,
                            message: '请选择状态',
                            trigger: 'change',
                        },
                    ]"
                >
                    <el-select
                        v-model="editForm.status"
                        placeholder="请选择状态"
                    >
                        <el-option label="显示" value="show"></el-option>
                        <el-option label="隐藏" value="hide"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="updateAnnouncement"
                    >确 定</el-button
                >
            </template>
        </el-dialog>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from "vue";
import api from "@/api"; // 导入封装的 API 实例
import { ElMessage, ElMessageBox } from "element-plus";
import { Timer, Search } from "@element-plus/icons-vue";
interface Creator {
    id: number;
    email: string | null;
    phone: string | null;
}

interface Announcement {
    id: number;
    title: string;
    content: string;
    status: string;
    creator: Creator;
    createdAt: string;
    updatedAt: string;
}

const announcements = ref<Announcement[]>([]);
const editDialogVisible = ref(false); // 控制对话框的显示
const editForm = ref<Announcement>({
    id: 0,
    title: "",
    content: "",
    status: "",
    creator: { id: 0, email: null, phone: null },
    createdAt: "",
    updatedAt: "",
});
const searchQuery = ref(""); // 搜索内容
const filteredAnnouncements = ref<Announcement[]>([]); // 过滤后的公告列表

// 获取公告数据
const fetchAnnouncements = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        const response = await api.get("/API/announcements", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        announcements.value = response.data || [];
        filteredAnnouncements.value = announcements.value; // 初始化过滤后的公告列表
    } catch (error) {
        console.error("获取公告信息失败", error);
    }
};

// 搜索公告
const searchAnnouncements = () => {
    if (searchQuery.value.trim() === "") {
        filteredAnnouncements.value = announcements.value; // 如果搜索框为空，显示所有公告
    } else {
        filteredAnnouncements.value = announcements.value.filter(
            (announcement) => {
                return (
                    announcement.id.toString().includes(searchQuery.value) ||
                    announcement.title.includes(searchQuery.value)
                );
            }
        );
    }
};

// 格式化日期
const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString(); // 根据需要格式化日期
};

// 打开编辑对话框并填充数据
const openEditDialog = (announcement: Announcement) => {
    editForm.value = { ...announcement }; // 复制公告信息到编辑表单
    editDialogVisible.value = true; // 显示对话框
};

// 更新公告信息
const updateAnnouncement = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        await api.put(
            `/API/announcements/${editForm.value.id}`,
            editForm.value,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        ElMessage.success("公告更新成功");
        editDialogVisible.value = false; // 关闭对话框
        await fetchAnnouncements(); // 重新获取公告数据
    } catch (error) {
        ElMessage.error("更新公告失败");
        console.error("更新公告失败:", error);
    }
};

// 更新状态
const updateStatus = async (id: number, newStatus: string) => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        await api.put(
            `/API/announcements/${id}`,
            { status: newStatus },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        ElMessage.success("状态更新成功");
        await fetchAnnouncements(); // 刷新公告列表
    } catch (error) {
        ElMessage.error("状态更新失败");
        console.error("状态更新失败:", error);
    }
};

// 确认删除公告
const confirmDelete = (id: number) => {
    ElMessageBox.confirm("您确定要删除此公告吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
    })
        .then(() => {
            handleDelete(id);
        })
        .catch(() => {
            ElMessage.info("删除已取消");
        });
};

// 删除公告
const handleDelete = async (id: number) => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        await api.delete(`/API/announcements/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        ElMessage.success("公告删除成功");
        await fetchAnnouncements(); // 刷新公告列表
    } catch (error) {
        console.error("删除公告失败", error);
        ElMessage.error("删除公告失败");
    }
};

// 组件挂载时获取公告数据
onMounted(fetchAnnouncements);
</script>

<style scoped>
/* 可以添加您自己的样式 */
</style>
