<template>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="账户设置" name="first">
            <el-descriptions
                direction="vertical"
                border
                style="margin-top: 20px"
            >
                <el-descriptions-item
                    :rowspan="2"
                    :width="140"
                    label="头像"
                    align="center"
                >
                    <div class="avatar-container">
                        <el-image
                            class="avatar-image"
                            :src="
                                userInfo.avatarUrl ||
                                'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
                            "
                        />
                        <div class="overlay1" @click="openAvatarDialog">
                            点击修改头像
                        </div>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item label="昵称">
                    <div class="nickname-container">
                        <span v-if="!isEditingNickname">{{
                            userInfo.nickname
                        }}</span>
                        <span
                            v-if="!isEditingNickname"
                            class="nickname-edit-link"
                            @click="startEditNickname"
                        >
                            修改
                        </span>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item label="密码">
                    ***********
                    <span class="password-edit-link" @click="openPasswordDialog"
                        >修改</span
                    >
                </el-descriptions-item>
                <el-descriptions-item label="手机号">
                    {{ userInfo.phone }}
                    <!-- <span class="phone-edit-link">修改</span> -->
                </el-descriptions-item>
                <el-descriptions-item label="角色">
                    <el-tag size="small">{{
                        formatRole(userInfo.role)
                    }}</el-tag>
                </el-descriptions-item>
            </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="基本信息" name="second">
            <el-descriptions
                direction="vertical"
                :column="4"
                style="margin-top: 20px"
                border
            >
                <el-descriptions-item label="用户ID">
                    <el-tag size="small">{{ userInfo.id }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="创建日期">{{
                    formatDate(userInfo.createdAt)
                }}</el-descriptions-item>
                <el-descriptions-item label="修改日期">{{
                    formatDate(userInfo.updatedAt)
                }}</el-descriptions-item>
                <el-descriptions-item label="邮箱" :span="2">{{
                    userInfo.email || "未提供"
                }}</el-descriptions-item>
                <el-descriptions-item label="性别">
                    <el-tag size="small">{{ userInfo.gender }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="地址">{{
                    userInfo.address
                }}</el-descriptions-item>
                <el-descriptions-item label="上一次登录地址"
                    >{{ ipAddressInfo.info1 || "未知省" }}
                    {{ ipAddressInfo.info2 || "未知市" }}
                    {{ ipAddressInfo.info3 || "未知区" }}</el-descriptions-item
                >
            </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="角色" name="third">
            <div>
                <h1>视图3</h1>
            </div>
        </el-tab-pane>
        <el-tab-pane label="任务" name="fourth">
            <div>
                <h1>视图4</h1>
            </div>
        </el-tab-pane>
    </el-tabs>
    <!-- 头像上传对话框 -->
    <el-dialog
        v-model="avatarDialogVisible"
        title="上传头像"
        width="30%"
        :before-close="handleClose"
    >
        <el-upload
            class="avatar-uploader"
            action="https://connect.yzbhgzm.cn:9443/api/upload/file"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :data="{ folder: 'avatar' }"
        >
            <img v-if="avatarUrl" :src="avatarUrl" class="avatar-preview" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="avatarDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="updateAvatar"
                    >确 定</el-button
                >
            </span>
        </template>
    </el-dialog>
    <!-- 修改昵称对话框 -->
    <el-dialog
        v-model="nicknameDialogVisible"
        title="修改昵称"
        width="30%"
        :before-close="closeNicknameDialog"
    >
        <el-input v-model="newNickname" placeholder="请输入新昵称"></el-input>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="closeNicknameDialog">取 消</el-button>
                <el-button type="primary" @click="updateNickname"
                    >确 定</el-button
                >
            </span>
        </template>
    </el-dialog>
    <!-- 修改密码对话框 -->
    <el-dialog
        v-model="passwordDialogVisible"
        title="修改密码"
        width="30%"
        :before-close="closePasswordDialog"
    >
        <el-form :model="passwordForm" ref="passwordFormRef">
            <el-form-item
                label="当前密码"
                prop="currentPassword"
                :rules="[{ required: true, message: '请输入当前密码' }]"
            >
                <el-input
                    type="password"
                    v-model="passwordForm.currentPassword"
                    placeholder="请输入当前密码"
                    autocomplete="off"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="新密码"
                prop="newPassword"
                :rules="[{ required: true, message: '请输入新密码' }]"
            >
                <el-input
                    type="password"
                    v-model="passwordForm.newPassword"
                    placeholder="请输入新密码"
                    autocomplete="off"
                ></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="closePasswordDialog">取 消</el-button>
                <el-button type="primary" @click="updatePassword"
                    >确 定</el-button
                >
            </span>
        </template>
    </el-dialog>
</template>
<script lang="ts" setup>
import { ref, onMounted } from "vue";

import api from "@/api"; // 导入封装的 API 实例
import { ElMessage } from "element-plus";
interface UserInfo {
    id?: string;
    avatarUrl?: string;
    nickname?: string;
    phone?: string;
    address?: string;
    gender?: string;
    role?: string;
    createdAt?: string;
    updatedAt?: string;
    email?: string; // 添加 email 属性
    ip?: string;
}
interface IpAddressInfo {
    info1?: string;
    info2?: string;
    info3?: string;
}
const isEditingNickname = ref(false);
const activeName = ref("first");
const userInfo = ref<UserInfo>({});
const avatarDialogVisible = ref(false);
const nicknameDialogVisible = ref(false);
const passwordDialogVisible = ref(false);
const avatarUrl = ref<string | null>(null);
const newNickname = ref<string>("");
const passwordForm = ref({
    currentPassword: "",
    newPassword: "",
});
const ipAddressInfo = ref<IpAddressInfo>({
    info1: "",
    info2: "",
    info3: "",
});

// 获取用户信息
const fetchUserInfo = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        const response = await api.get("/API/userInfo", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        userInfo.value = response.data.userInfo || {};
        if (userInfo.value.ip) {
            await fetchIpAddressInfo(userInfo.value.ip);
        }
    } catch (error) {
        console.error("获取用户信息失败", error);
    }
};
const fetchIpAddressInfo = async (ip: string) => {
    try {
        const response = await fetch(
            `https://api.vore.top/api/IPdata?ip=${ip}`
        );
        const data = await response.json();
        if (data.code === 200) {
            ipAddressInfo.value = data.ipdata; // 存储 IP 地址信息
        } else {
            console.error("获取 IP 地址信息失败", data.msg);
        }
    } catch (error) {
        console.error("请求 IP 地址信息失败", error);
    }
};
// 格式化角色
const formatRole = (role: string | undefined): string => {
    switch (role) {
        case "SUPERADMIN":
            return "超级管理员";
        case "ADMIN":
            return "管理员";
        case "USER":
            return "用户";
        default:
            return "未知角色";
    }
};
// 格式化日期
const formatDate = (dateString: string | undefined): string => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，所以要加1
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}年${month}月${day}日，${hours}时${minutes}分${seconds}秒`;
};
// 打开头像对话框
const openAvatarDialog = () => {
    avatarDialogVisible.value = true;
};
// 打开修改昵称对话框
const startEditNickname = () => {
    newNickname.value = userInfo.value.nickname || ""; // 设置输入框为当前昵称
    nicknameDialogVisible.value = true; // 显示修改昵称对话框
};
// 打开修改密码对话框
const openPasswordDialog = () => {
    passwordDialogVisible.value = true; // 显示修改密码对话框
};
// 关闭昵称对话框
const closeNicknameDialog = () => {
    nicknameDialogVisible.value = false; // 关闭对话框
};
// 关闭密码对话框
const closePasswordDialog = () => {
    passwordDialogVisible.value = false; // 关闭对话框
    passwordForm.value.currentPassword = ""; // 清空当前密码
    passwordForm.value.newPassword = ""; // 清空新密码
};
// 头像上传成功的处理
const handleAvatarSuccess = (response: string) => {
    console.log("上传成功的响应:", response);
    avatarUrl.value = response; // 更新 avatarUrl
    userInfo.value.avatarUrl = avatarUrl.value; // 更新用户信息中的头像 URL
    ElMessage.success("头像上传成功");
};
// 上传前的验证
const beforeAvatarUpload = (file: File) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
        ElMessage.error("上传头像图片只能是 JPG/PNG 格式!");
    }
    return isImage;
};
// 更新头像
const updateAvatar = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        await api.put(
            "/UPDATE/avatar",
            {
                avatarUrl: avatarUrl.value,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        avatarDialogVisible.value = false;
        window.location.reload();
        ElMessage.success("头像更新成功");
    } catch (error) {
        console.error("更新头像失败", error);
        ElMessage.error("头像更新失败");
    }
};
// 更新昵称
const updateNickname = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        await api.put(
            "/UPDATE/nickname",
            {
                nickname: newNickname.value,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        userInfo.value.nickname = newNickname.value; // 更新用户信息中的昵称
        ElMessage.success("昵称更新成功");
        closeNicknameDialog(); // 关闭对话框
    } catch (error) {
        console.error("更新昵称失败", error);
        ElMessage.error("昵称更新失败");
    }
};
// 更新密码
const updatePassword = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        await api.put(
            "/UPDATE/password",
            {
                currentPassword: passwordForm.value.currentPassword,
                newPassword: passwordForm.value.newPassword,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        ElMessage.success("密码更新成功");
        closePasswordDialog(); // 关闭对话框
    } catch (error) {
        console.error("更新密码失败", error);
        ElMessage.error("密码更新失败");
    }
};
// 关闭头像对话框
const handleClose = () => {
    avatarDialogVisible.value = false;
};
// 点击标签的处理
const handleClick = (tab: any) => {
    console.log("点击的标签:", tab);
};
// 组件挂载后获取用户信息
onMounted(fetchUserInfo);
</script>
<style>
.avatar-container {
    position: relative;
    display: inline-block;
}
.overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0; /* 默认隐藏 */
    transition: opacity 0.3s;
}
.avatar-container:hover .overlay1 {
    width: 100%;
    height: 100%;
    opacity: 1; /* 鼠标悬停时显示蒙版 */
}
.avatar-uploader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatar-preview {
    width: 100px;
    height: 100px;
    display: block;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}
.nickname-container {
    display: flex;
    align-items: center;
}
.nickname-edit-link {
    margin-left: 60%;
    cursor: pointer;
    color: #409eff;
}
.password-edit-link {
    margin-left: 60%;
    cursor: pointer;
    color: #409eff;
}
.phone-edit-link {
    color: #409eff;
    cursor: pointer;
    margin-left: 60%;
}
</style>
