import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, onMounted } from "vue";
import api from "@/api"; // 导入封装的 API 实例
import { ElMessage } from "element-plus";

interface AnnouncementForm {
    title: string;
    content: string;
    status: string; // 状态
    creatorId: number; // 发布人ID
}


export default /*@__PURE__*/_defineComponent({
  __name: 'addannouncement',
  setup(__props) {

const announcementForm = ref<AnnouncementForm>({
    title: "",
    content: "",
    status: "show", // 默认状态
    creatorId: 0, // 初始化为0
});

const currentUser = ref<{ id: number; nickname: string }>({
    id: 0,
    nickname: "",
}); // 当前用户信息

// 获取当前用户信息
const fetchCurrentUser = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        const response = await api.get("/API/userInfo", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        currentUser.value = response.data.userInfo; // 假设返回的数据包含用户的 ID 和昵称
        announcementForm.value.creatorId = currentUser.value.id; // 设置发布人ID
    } catch (error: any) {
        // 将 error 指定为 any 类型
        console.error("获取用户信息失败", error);
        ElMessage.error("获取用户信息失败");
    }
};

// 提交公告
const submitAnnouncement = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        await api.post(
            "/API/add/announcements",
            {
                title: announcementForm.value.title,
                content: announcementForm.value.content,
                status: announcementForm.value.status,
                creator: {
                    id: announcementForm.value.creatorId, // 发送用户ID
                },
                createdAt: new Date().toISOString(), // 设置创建时间
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        ElMessage.success("公告添加成功");
        resetForm(); // 重置表单
    } catch (error: any) {
        // 将 error 指定为 any 类型
        console.error(
            "添加公告失败",
            error.response ? error.response.data : error
        );
        ElMessage.error(
            "添加公告失败: " +
                (error.response ? error.response.data.message : "未知错误")
        );
    }
};

// 重置表单
const resetForm = () => {
    announcementForm.value = {
        title: "",
        content: "",
        status: "show", // 默认状态
        creatorId: currentUser.value.id, // 保持发布人ID不变
    };
};

// 组件挂载时获取用户信息
onMounted(fetchCurrentUser);

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[7] || (_cache[7] = _createElementVNode("h1", null, "添加公告", -1)),
    _createVNode(_component_el_form, {
      model: announcementForm.value,
      ref: "announcementFormRef",
      "label-width": "100px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "公告标题",
          prop: "title",
          rules: [
                    {
                        required: true,
                        message: '请输入公告标题',
                        trigger: 'blur',
                    },
                ]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: announcementForm.value.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((announcementForm.value.title) = $event)),
              placeholder: "请输入公告标题"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "公告内容",
          prop: "content",
          rules: [
                    {
                        required: true,
                        message: '请输入公告内容',
                        trigger: 'blur',
                    },
                ]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              type: "textarea",
              modelValue: announcementForm.value.content,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((announcementForm.value.content) = $event)),
              placeholder: "请输入公告内容",
              rows: 4
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "状态",
          prop: "status",
          rules: [
                    {
                        required: true,
                        message: '请选择状态',
                        trigger: 'change',
                    },
                ]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: announcementForm.value.status,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((announcementForm.value.status) = $event)),
              placeholder: "请选择状态"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_option, {
                  label: "显示",
                  value: "show"
                }),
                _createVNode(_component_el_option, {
                  label: "隐藏",
                  value: "hide"
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "发布人",
          prop: "creator"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: currentUser.value.nickname,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((currentUser.value.nickname) = $event)),
              disabled: ""
            }, null, 8, ["modelValue"]),
            _withDirectives(_createElementVNode("input", {
              type: "hidden",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((announcementForm.value.creatorId) = $event))
            }, null, 512), [
              [_vModelText, announcementForm.value.creatorId]
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: submitAnnouncement
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("提交")
              ])),
              _: 1
            }),
            _createVNode(_component_el_button, { onClick: resetForm }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("重置")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})