import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"20px","display":"flex","align-items":"center"} }
const _hoisted_2 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_3 = ["src"]
const _hoisted_4 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_5 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_6 = { style: {"margin-left":"10px"} }
const _hoisted_7 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_8 = { style: {"margin-left":"10px"} }
const _hoisted_9 = { style: {"display":"flex"} }

import { Timer, Search } from "@element-plus/icons-vue";
import { ref, onMounted } from "vue";
import api from "@/api"; // 导入封装的 API 实例
import { ElMessage, ElMessageBox } from "element-plus";
import * as XLSX from "xlsx";

interface User {
    id: string;
    avatarUrl: string; // 头像 URL
    nickname: string;
    phone: string;
    role: string;
    gender: string;
    level: number; // 修改为 number 类型
    createdAt: string;
    updatedAt: string;
    address: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'userlist',
  setup(__props) {

const tableData = ref<User[]>([]);
const filteredData = ref<User[]>([]); // 初始化为一个空数组
const searchQuery = ref<string>("");
const editDialogVisible = ref(false); // 控制对话框的显示
const editForm = ref<User>({
    id: "",
    avatarUrl: "",
    nickname: "",
    phone: "",
    role: "",
    gender: "",
    level: 1, // 默认等级
    createdAt: "",
    updatedAt: "",
    address: "",
});

// 获取用户数据
const fetchData = async () => {
    try {
        const response = await api.get("/API/usersInfo"); // 使用封装的 API 实例
        if (Array.isArray(response.data.users)) {
            tableData.value = response.data.users; // 确保这是一个数组
            filteredData.value = tableData.value; // 初始化 filteredData 为所有数据
        } else {
            console.error("返回的数据不是数组:");
        }
    } catch (error) {
        console.error("获取用户信息失败:", error);
    }
};

// 组件挂载时获取数据
onMounted(() => {
    fetchData();
});

// 打开编辑对话框并填充数据
const openEditDialog = (user: User) => {
    editForm.value = { ...user }; // 复制用户信息到编辑表单
    editDialogVisible.value = true; // 显示对话框
};

// 更新用户信息
const updateUser = async () => {
    try {
        const userId = editForm.value.id; // 获取用户 ID
        await api.put(`/UPDATE/users/${userId}`, editForm.value); // 使用封装的 API 实例
        ElMessage.success("用户信息更新成功");
        editDialogVisible.value = false; // 关闭对话框
        await fetchData(); // 重新获取用户数据
    } catch (error) {
        ElMessage.error("更新用户信息失败");
        console.error("更新用户信息失败:", error);
    }
};

// 搜索用户
const searchUsers = () => {
    try {
        if (!searchQuery.value) {
            filteredData.value = tableData.value; // 如果没有搜索条件，返回所有数据
        } else {
            const query = searchQuery.value.toLowerCase(); // 这里确保 searchQuery 是字符串
            filteredData.value = tableData.value.filter(
                (user) =>
                    String(user.id).includes(query) ||
                    (user.nickname &&
                        user.nickname.toLowerCase().includes(query))
            );
        }
    } catch (error) {
        ElMessage.error("搜索过程中发生错误");
        console.error("搜索错误:", error);
    }
};

// 获取角色名称
const getRoleName = (role: string) => {
    switch (role) {
        case "SUPERADMIN":
            return "超级管理员";
        case "ADMIN":
            return "管理员";
        case "USER":
            return "普通用户";
        default:
            return "未知角色";
    }
};

// 格式化日期
const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
};

// 确认删除用户
const confirmDelete = (userId: string) => {
    ElMessageBox.confirm("确定要删除该用户吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
    })
        .then(async () => {
            await handleDelete(userId);
        })
        .catch(() => {
            ElMessage.info("取消删除");
        });
};

// 删除用户
const handleDelete = async (userId: string) => {
    try {
        await api.delete(`/DELETE/user/${userId}`); // 使用封装的 API 实例
        ElMessage.success(`用户ID:${userId} 已删除`);
        // 重新获取数据以更新表格
        await fetchData(); // 重新获取用户数据
    } catch (error) {
        ElMessage.error("删除用户失败");
        console.error("删除用户失败:", error);
    }
};

// 更新用户状态
const updateUserStatus = async (userId: string, status: string) => {
    try {
        const response = await api.put(`/UPDATE/status/${userId}`, null, {
            params: { status: status },
        }); // 使用封装的 API 实例
        ElMessage.success(response.data);
        // 重新获取数据以更新表格
        await fetchData(); // 重新获取用户数据
    } catch (error) {
        ElMessage.error("更新用户状态失败");
        console.error("更新用户状态失败:", error);
    }
};

// 导出为Excel
const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "用户列表");
    XLSX.writeFile(workbook, "用户列表.xlsx");
};

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_input, {
        modelValue: searchQuery.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
        placeholder: "请输入用户ID或昵称",
        style: {"width":"300px"}
      }, null, 8, ["modelValue"]),
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: searchUsers,
        icon: _unref(Search),
        style: {"margin-left":"10px"}
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode(" 搜索 ")
        ])),
        _: 1
      }, 8, ["icon"]),
      _createVNode(_component_el_button, {
        type: "success",
        onClick: exportToExcel,
        style: {"margin-left":"10px"}
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createTextVNode(" 导出为Excel ")
        ])),
        _: 1
      })
    ]),
    _createVNode(_component_el_table, {
      data: filteredData.value,
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "id",
          label: "ID",
          width: "120"
        }),
        _createVNode(_component_el_table_column, {
          label: "头像",
          width: "180"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: scope.row.avatarUrl,
                alt: "头像",
                style: {"width":"40px","height":"40px","border-radius":"50%","object-fit":"cover","object-position":"center"}
              }, null, 8, _hoisted_3)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "昵称",
          width: "180"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", null, _toDisplayString(scope.row.nickname), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "联系方式",
          width: "180"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", null, _toDisplayString(scope.row.phone), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "性别",
          width: "180"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_popover, {
              effect: "light",
              trigger: "hover",
              placement: "top",
              width: "auto"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, "性别: " + _toDisplayString(scope.row.gender), 1)
              ]),
              reference: _withCtx(() => [
                _createVNode(_component_el_tag, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(scope.row.gender), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "角色",
          width: "180"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_popover, {
              effect: "light",
              trigger: "hover",
              placement: "top",
              width: "auto"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, "角色: " + _toDisplayString(getRoleName(scope.row.role)), 1),
                _createElementVNode("div", null, "等级: " + _toDisplayString(scope.row.level), 1)
              ]),
              reference: _withCtx(() => [
                _createVNode(_component_el_tag, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(getRoleName(scope.row.role)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "状态",
          width: "180"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_switch, {
              modelValue: scope.row.status,
              "onUpdate:modelValue": ($event: any) => ((scope.row.status) = $event),
              "active-value": 'Active',
              "inactive-value": 'Frozen',
              style: {"--el-switch-on-color":"#13ce66","--el-switch-off-color":"#ff4949"},
              onChange: ($event: any) => (
                            updateUserStatus(scope.row.id, scope.row.status)
                        )
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]),
            _createElementVNode("span", _hoisted_6, _toDisplayString(scope.row.status === "Active" ? "正常" : "封禁"), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "创建日期",
          width: "220"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_unref(Timer))
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_8, _toDisplayString(formatDate(scope.row.createdAt)), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "操作",
          width: "240"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_el_button, {
                size: "small",
                type: "primary",
                onClick: ($event: any) => (openEditDialog(scope.row))
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode(" 设置 ")
                ])),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_el_button, {
                size: "small",
                type: "danger",
                onClick: ($event: any) => (confirmDelete(scope.row.id))
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode(" 删除 ")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createVNode(_component_el_dialog, {
      title: "编辑用户信息",
      modelValue: editDialogVisible.value,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((editDialogVisible).value = $event)),
      width: "30%"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_el_button, {
          onClick: _cache[6] || (_cache[6] = ($event: any) => (editDialogVisible.value = false))
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [
            _createTextVNode("取 消")
          ])),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: updateUser
        }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createTextVNode("确 定")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: editForm.value,
          ref: "editFormRef",
          "label-width": "100px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "昵称",
              prop: "nickname",
              rules: [
                        {
                            required: true,
                            message: '请输入昵称',
                            trigger: 'blur',
                        },
                    ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: editForm.value.nickname,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((editForm.value.nickname) = $event)),
                  placeholder: "请输入昵称"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "性别",
              prop: "gender",
              rules: [
                        {
                            required: true,
                            message: '请选择性别',
                            trigger: 'change',
                        },
                    ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: editForm.value.gender,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((editForm.value.gender) = $event)),
                  placeholder: "请选择性别"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "男",
                      value: "男"
                    }),
                    _createVNode(_component_el_option, {
                      label: "女",
                      value: "女"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "等级",
              prop: "level",
              rules: [
                        {
                            required: true,
                            message: '请选择等级',
                            trigger: 'change',
                        },
                    ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: editForm.value.level,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((editForm.value.level) = $event)),
                  placeholder: "请选择等级"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5], (level) => {
                      return _createVNode(_component_el_option, {
                        key: level,
                        label: level,
                        value: level
                      }, null, 8, ["label", "value"])
                    }), 64))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "角色",
              prop: "role",
              rules: [
                        {
                            required: true,
                            message: '请选择角色',
                            trigger: 'change',
                        },
                    ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: editForm.value.role,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((editForm.value.role) = $event)),
                  placeholder: "请选择角色"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "超级管理员",
                      value: "SUPERADMIN"
                    }),
                    _createVNode(_component_el_option, {
                      label: "管理员",
                      value: "ADMIN"
                    }),
                    _createVNode(_component_el_option, {
                      label: "用户",
                      value: "USER"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "地址",
              prop: "address",
              rules: [
                        {
                            required: true,
                            message: '请输入地址',
                            trigger: 'blur',
                        },
                    ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: editForm.value.address,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((editForm.value.address) = $event)),
                  placeholder: "请输入地址"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})