// src/api.ts
import axios from "axios";

const api = axios.create({
    // baseURL: "http://localhost:8080", // 设置基础 URL
    baseURL: "https://connect.yzbhgzm.cn:9443", // 设置基础 URL
    timeout: 10000, // 设置请求超时时间
});

// 导出 API 实例
export default api;
