import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "avatar-container" }
const _hoisted_2 = { class: "nickname-container" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "el-icon-plus avatar-uploader-icon"
}
const _hoisted_6 = { class: "dialog-footer" }
const _hoisted_7 = { class: "dialog-footer" }
const _hoisted_8 = { class: "dialog-footer" }

import { ref, onMounted } from "vue";

import api from "@/api"; // 导入封装的 API 实例
import { ElMessage } from "element-plus";
interface UserInfo {
    id?: string;
    avatarUrl?: string;
    nickname?: string;
    phone?: string;
    address?: string;
    gender?: string;
    role?: string;
    createdAt?: string;
    updatedAt?: string;
    email?: string; // 添加 email 属性
    ip?: string;
}
interface IpAddressInfo {
    info1?: string;
    info2?: string;
    info3?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'usersetting',
  setup(__props) {

const isEditingNickname = ref(false);
const activeName = ref("first");
const userInfo = ref<UserInfo>({});
const avatarDialogVisible = ref(false);
const nicknameDialogVisible = ref(false);
const passwordDialogVisible = ref(false);
const avatarUrl = ref<string | null>(null);
const newNickname = ref<string>("");
const passwordForm = ref({
    currentPassword: "",
    newPassword: "",
});
const ipAddressInfo = ref<IpAddressInfo>({
    info1: "",
    info2: "",
    info3: "",
});

// 获取用户信息
const fetchUserInfo = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        const response = await api.get("/API/userInfo", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        userInfo.value = response.data.userInfo || {};
        if (userInfo.value.ip) {
            await fetchIpAddressInfo(userInfo.value.ip);
        }
    } catch (error) {
        console.error("获取用户信息失败", error);
    }
};
const fetchIpAddressInfo = async (ip: string) => {
    try {
        const response = await fetch(
            `https://api.vore.top/api/IPdata?ip=${ip}`
        );
        const data = await response.json();
        if (data.code === 200) {
            ipAddressInfo.value = data.ipdata; // 存储 IP 地址信息
        } else {
            console.error("获取 IP 地址信息失败", data.msg);
        }
    } catch (error) {
        console.error("请求 IP 地址信息失败", error);
    }
};
// 格式化角色
const formatRole = (role: string | undefined): string => {
    switch (role) {
        case "SUPERADMIN":
            return "超级管理员";
        case "ADMIN":
            return "管理员";
        case "USER":
            return "用户";
        default:
            return "未知角色";
    }
};
// 格式化日期
const formatDate = (dateString: string | undefined): string => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，所以要加1
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}年${month}月${day}日，${hours}时${minutes}分${seconds}秒`;
};
// 打开头像对话框
const openAvatarDialog = () => {
    avatarDialogVisible.value = true;
};
// 打开修改昵称对话框
const startEditNickname = () => {
    newNickname.value = userInfo.value.nickname || ""; // 设置输入框为当前昵称
    nicknameDialogVisible.value = true; // 显示修改昵称对话框
};
// 打开修改密码对话框
const openPasswordDialog = () => {
    passwordDialogVisible.value = true; // 显示修改密码对话框
};
// 关闭昵称对话框
const closeNicknameDialog = () => {
    nicknameDialogVisible.value = false; // 关闭对话框
};
// 关闭密码对话框
const closePasswordDialog = () => {
    passwordDialogVisible.value = false; // 关闭对话框
    passwordForm.value.currentPassword = ""; // 清空当前密码
    passwordForm.value.newPassword = ""; // 清空新密码
};
// 头像上传成功的处理
const handleAvatarSuccess = (response: string) => {
    console.log("上传成功的响应:", response);
    avatarUrl.value = response; // 更新 avatarUrl
    userInfo.value.avatarUrl = avatarUrl.value; // 更新用户信息中的头像 URL
    ElMessage.success("头像上传成功");
};
// 上传前的验证
const beforeAvatarUpload = (file: File) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
        ElMessage.error("上传头像图片只能是 JPG/PNG 格式!");
    }
    return isImage;
};
// 更新头像
const updateAvatar = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        await api.put(
            "/UPDATE/avatar",
            {
                avatarUrl: avatarUrl.value,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        avatarDialogVisible.value = false;
        window.location.reload();
        ElMessage.success("头像更新成功");
    } catch (error) {
        console.error("更新头像失败", error);
        ElMessage.error("头像更新失败");
    }
};
// 更新昵称
const updateNickname = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        await api.put(
            "/UPDATE/nickname",
            {
                nickname: newNickname.value,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        userInfo.value.nickname = newNickname.value; // 更新用户信息中的昵称
        ElMessage.success("昵称更新成功");
        closeNicknameDialog(); // 关闭对话框
    } catch (error) {
        console.error("更新昵称失败", error);
        ElMessage.error("昵称更新失败");
    }
};
// 更新密码
const updatePassword = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        await api.put(
            "/UPDATE/password",
            {
                currentPassword: passwordForm.value.currentPassword,
                newPassword: passwordForm.value.newPassword,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        ElMessage.success("密码更新成功");
        closePasswordDialog(); // 关闭对话框
    } catch (error) {
        console.error("更新密码失败", error);
        ElMessage.error("密码更新失败");
    }
};
// 关闭头像对话框
const handleClose = () => {
    avatarDialogVisible.value = false;
};
// 点击标签的处理
const handleClick = (tab: any) => {
    console.log("点击的标签:", tab);
};
// 组件挂载后获取用户信息
onMounted(fetchUserInfo);

return (_ctx: any,_cache: any) => {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_tabs, {
      modelValue: activeName.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((activeName).value = $event)),
      class: "demo-tabs",
      onTabClick: handleClick
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "账户设置",
          name: "first"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_descriptions, {
              direction: "vertical",
              border: "",
              style: {"margin-top":"20px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions_item, {
                  rowspan: 2,
                  width: 140,
                  label: "头像",
                  align: "center"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_el_image, {
                        class: "avatar-image",
                        src: 
                                userInfo.value.avatarUrl ||
                                'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
                            
                      }, null, 8, ["src"]),
                      _createElementVNode("div", {
                        class: "overlay1",
                        onClick: openAvatarDialog
                      }, " 点击修改头像 ")
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_descriptions_item, { label: "昵称" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      (!isEditingNickname.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(userInfo.value.nickname), 1))
                        : _createCommentVNode("", true),
                      (!isEditingNickname.value)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: "nickname-edit-link",
                            onClick: startEditNickname
                          }, " 修改 "))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_descriptions_item, { label: "密码" }, {
                  default: _withCtx(() => [
                    _cache[8] || (_cache[8] = _createTextVNode(" *********** ")),
                    _createElementVNode("span", {
                      class: "password-edit-link",
                      onClick: openPasswordDialog
                    }, "修改")
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_descriptions_item, { label: "手机号" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(userInfo.value.phone), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_descriptions_item, { label: "角色" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_tag, { size: "small" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(formatRole(userInfo.value.role)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "基本信息",
          name: "second"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_descriptions, {
              direction: "vertical",
              column: 4,
              style: {"margin-top":"20px"},
              border: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions_item, { label: "用户ID" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_tag, { size: "small" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userInfo.value.id), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_descriptions_item, { label: "创建日期" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(formatDate(userInfo.value.createdAt)), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_descriptions_item, { label: "修改日期" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(formatDate(userInfo.value.updatedAt)), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_descriptions_item, {
                  label: "邮箱",
                  span: 2
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(userInfo.value.email || "未提供"), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_descriptions_item, { label: "性别" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_tag, { size: "small" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userInfo.value.gender), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_descriptions_item, { label: "地址" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(userInfo.value.address), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_descriptions_item, { label: "上一次登录地址" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(ipAddressInfo.value.info1 || "未知省") + " " + _toDisplayString(ipAddressInfo.value.info2 || "未知市") + " " + _toDisplayString(ipAddressInfo.value.info3 || "未知区"), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "角色",
          name: "third"
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createElementVNode("div", null, [
              _createElementVNode("h1", null, "视图3")
            ], -1)
          ])),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "任务",
          name: "fourth"
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createElementVNode("div", null, [
              _createElementVNode("h1", null, "视图4")
            ], -1)
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      modelValue: avatarDialogVisible.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((avatarDialogVisible).value = $event)),
      title: "上传头像",
      width: "30%",
      "before-close": handleClose
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_6, [
          _createVNode(_component_el_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (avatarDialogVisible.value = false))
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode("取 消")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: updateAvatar
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createTextVNode("确 定")
            ])),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_upload, {
          class: "avatar-uploader",
          action: "https://connect.yzbhgzm.cn:9443/api/upload/file",
          "show-file-list": false,
          "on-success": handleAvatarSuccess,
          "before-upload": beforeAvatarUpload,
          data: { folder: 'avatar' }
        }, {
          default: _withCtx(() => [
            (avatarUrl.value)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: avatarUrl.value,
                  class: "avatar-preview"
                }, null, 8, _hoisted_4))
              : (_openBlock(), _createElementBlock("i", _hoisted_5))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      modelValue: nicknameDialogVisible.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((nicknameDialogVisible).value = $event)),
      title: "修改昵称",
      width: "30%",
      "before-close": closeNicknameDialog
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_7, [
          _createVNode(_component_el_button, { onClick: closeNicknameDialog }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createTextVNode("取 消")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: updateNickname
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode("确 定")
            ])),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: newNickname.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((newNickname).value = $event)),
          placeholder: "请输入新昵称"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      modelValue: passwordDialogVisible.value,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((passwordDialogVisible).value = $event)),
      title: "修改密码",
      width: "30%",
      "before-close": closePasswordDialog
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_8, [
          _createVNode(_component_el_button, { onClick: closePasswordDialog }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [
              _createTextVNode("取 消")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: updatePassword
          }, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [
              _createTextVNode("确 定")
            ])),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: passwordForm.value,
          ref: "passwordFormRef"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "当前密码",
              prop: "currentPassword",
              rules: [{ required: true, message: '请输入当前密码' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "password",
                  modelValue: passwordForm.value.currentPassword,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((passwordForm.value.currentPassword) = $event)),
                  placeholder: "请输入当前密码",
                  autocomplete: "off"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "新密码",
              prop: "newPassword",
              rules: [{ required: true, message: '请输入新密码' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "password",
                  modelValue: passwordForm.value.newPassword,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((passwordForm.value.newPassword) = $event)),
                  placeholder: "请输入新密码",
                  autocomplete: "off"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})