import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"position":"relative","min-height":"400px"} }
const _hoisted_2 = { style: {"position":"absolute","bottom":"10px","left":"1px"} }

import { ref } from "vue";
import { useRouter } from "vue-router";
import {
    Avatar,
    Menu as IconMenu,
    Setting,
    User,
    Expand,
    Fold,
    Dish,
    Management,
} from "@element-plus/icons-vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Aside',
  setup(__props) {

const router = useRouter();
const isCollapse = ref(false);

const toggleCollapse = () => {
    isCollapse.value = !isCollapse.value;
    requestAnimationFrame(() => {
        // 可以在这里添加额外的动画逻辑
    });
};

const navigateTo = (path: string) => {
    router.push(path);
};

const handleOpen = (key: string, keyPath: string[]) => {
    console.log(key, keyPath);
};

const handleClose = (key: string, keyPath: string[]) => {
    console.log(key, keyPath);
};

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_menu, {
      "default-active": "/home",
      class: "el-menu-vertical-demo",
      collapse: isCollapse.value,
      onOpen: handleOpen,
      onClose: handleClose,
      "active-text-color": "#ffd04b",
      "background-color": "#545c64",
      "text-color": "#fff",
      style: {"height":"100%","padding-top":"10px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_menu_item, {
          index: "/",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (navigateTo('/')))
        }, {
          title: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode("首页")
          ])),
          default: _withCtx(() => [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_unref(IconMenu))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_sub_menu, { index: "/user" }, {
          title: _withCtx(() => [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_unref(Avatar))
              ]),
              _: 1
            }),
            _cache[11] || (_cache[11] = _createElementVNode("span", null, "个人中心", -1))
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_menu_item, {
              index: "/personal",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (navigateTo('/personal')))
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("个人信息")
              ])),
              _: 1
            }),
            _createVNode(_component_el_menu_item, {
              index: "/usersetting",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (navigateTo('/usersetting')))
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode("用户设置")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_sub_menu, { index: "/users" }, {
          title: _withCtx(() => [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_unref(User))
              ]),
              _: 1
            }),
            _cache[14] || (_cache[14] = _createElementVNode("span", null, "用户中心", -1))
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_menu_item, {
              index: "/userlist",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (navigateTo('/userlist')))
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("用户列表")
              ])),
              _: 1
            }),
            _createVNode(_component_el_menu_item, {
              index: "/adduser",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (navigateTo('/adduser')))
            }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode("添加用户")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_sub_menu, { index: "/system-announcement" }, {
          title: _withCtx(() => [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_unref(Management))
              ]),
              _: 1
            }),
            _cache[17] || (_cache[17] = _createElementVNode("span", null, "系统公告", -1))
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_menu_item, {
              index: "/announcement",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (navigateTo('/announcement')))
            }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode("公告列表")
              ])),
              _: 1
            }),
            _createVNode(_component_el_menu_item, {
              index: "/addannouncement",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (navigateTo('/addannouncement')))
            }, {
              default: _withCtx(() => _cache[19] || (_cache[19] = [
                _createTextVNode("添加公告")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_sub_menu, { index: "/dish" }, {
          title: _withCtx(() => [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_unref(Dish))
              ]),
              _: 1
            }),
            _cache[20] || (_cache[20] = _createElementVNode("span", null, "菜品管理", -1))
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_menu_item, {
              index: "/dish",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (navigateTo('/dish')))
            }, {
              default: _withCtx(() => _cache[21] || (_cache[21] = [
                _createTextVNode("菜品列表")
              ])),
              _: 1
            }),
            _createVNode(_component_el_menu_item, {
              index: "/adddish",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (navigateTo('/adddish')))
            }, {
              default: _withCtx(() => _cache[22] || (_cache[22] = [
                _createTextVNode("添加菜品")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_menu_item, {
          index: "/settings",
          onClick: _cache[9] || (_cache[9] = ($event: any) => (navigateTo('/settings')))
        }, {
          title: _withCtx(() => _cache[23] || (_cache[23] = [
            _createTextVNode("设置")
          ])),
          default: _withCtx(() => [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_unref(Setting))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_button, {
            onClick: toggleCollapse,
            style: {"border":"none","background-color":"#545c64","color":"white"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  (isCollapse.value)
                    ? (_openBlock(), _createBlock(_unref(Fold), { key: 0 }))
                    : (_openBlock(), _createBlock(_unref(Expand), { key: 1 }))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["collapse"])
  ]))
}
}

})