vue
<template>
    <el-descriptions title=" " border>
        <el-descriptions-item
            :rowspan="2"
            :width="140"
            label="头像"
            align="center"
        >
            <el-image
                style="width: 100px; height: 100px"
                :src="userInfo.avatarUrl || ''"
                fit="cover"
            />
        </el-descriptions-item>
        <el-descriptions-item label="昵称">{{
            userInfo.nickname || "未知"
        }}</el-descriptions-item>
        <el-descriptions-item label="电话">{{
            userInfo.phone || "未提供"
        }}</el-descriptions-item>
        <el-descriptions-item label="邮箱">{{
            userInfo.email || "未提供"
        }}</el-descriptions-item>
        <el-descriptions-item label="角色">
            <el-tag size="small">{{ getRoleName(userInfo.role) }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="地址">
            {{ userInfo.address || "未提供" }}
        </el-descriptions-item>
        <el-descriptions-item label="上一次登录地址">
            {{ ipAddressInfo.info1 || "未知省" }}
            {{ ipAddressInfo.info2 || "未知市" }}
            {{ ipAddressInfo.info3 || "未知区" }}
        </el-descriptions-item>
    </el-descriptions>
</template>

<script>
import api from "@/api"; // 导入封装的 API 实例
import { ref, onMounted } from "vue";

export default {
    setup() {
        const userInfo = ref({});
        const ipAddressInfo = ref({}); // 用于存储 IP 地址信息

        const fetchUserInfo = async () => {
            try {
                const token = sessionStorage.getItem("token");
                if (!token) throw new Error("Token not found");
                const { data } = await api.get("/API/userInfo", {
                    headers: { Authorization: `Bearer ${token}` },
                });
                userInfo.value = data.userInfo;
                // 获取 IP 地址信息
                if (userInfo.value.ip) {
                    await fetchIpAddressInfo(userInfo.value.ip);
                }
            } catch (error) {
                console.error("获取用户信息失败", error);
            }
        };

        const fetchIpAddressInfo = async (ip) => {
            try {
                const response = await fetch(
                    `https://api.vore.top/api/IPdata?ip=${ip}`
                );
                const data = await response.json();
                if (data.code === 200) {
                    ipAddressInfo.value = data.ipdata; // 存储 IP 地址信息
                } else {
                    console.error("获取 IP 地址信息失败", data.msg);
                }
            } catch (error) {
                console.error("请求 IP 地址信息失败", error);
            }
        };

        // 根据角色返回对应的中文名称
        const getRoleName = (role) => {
            switch (role) {
                case "SUPERADMIN":
                    return "超级管理员";
                case "ADMIN":
                    return "管理员";
                case "USER":
                    return "用户";
                default:
                    return "未知角色";
            }
        };

        onMounted(() => {
            fetchUserInfo();
        });

        return {
            userInfo,
            ipAddressInfo,
            getRoleName,
        };
    },
};
</script>

<style scoped>
/* 你的样式 */
</style>
