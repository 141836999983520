import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_Header = _resolveComponent("Header")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_MainContent = _resolveComponent("MainContent")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_aside, { class: "el-aside" }, {
          default: _withCtx(() => [
            _createVNode(_component_Sidebar, { class: "aside" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_header, null, {
              default: _withCtx(() => [
                _createVNode(_component_Header)
              ]),
              _: 1
            }),
            _createVNode(_component_el_main, null, {
              default: _withCtx(() => [
                _createVNode(_component_MainContent)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}