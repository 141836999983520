import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "button-group" }

import { reactive, ref } from "vue";
import api from "@/api"; // 导入封装的 API 实例
import { ElMessage } from "element-plus";


export default /*@__PURE__*/_defineComponent({
  __name: 'adduser',
  setup(__props) {

const registerFormRef = ref();
const registerForm = reactive({
    username: "",
    password: "",
    confirmPassword: "",
    phone: "",
    email: "",
});

const validateConfirmPassword = (
    rule: any,
    value: string,
    callback: (error?: string | Error) => void
) => {
    if (value === "") {
        callback(new Error("请确认密码"));
    } else if (value !== registerForm.password) {
        callback(new Error("两次输入的密码不一致"));
    } else {
        callback();
    }
};

const rules = {
    username: [
        { required: true, message: "用户名不能为空", trigger: "blur" },
        { min: 2, message: "用户名不能少于2个字符", trigger: "blur" },
    ],
    password: [
        { required: true, message: "密码不能为空", trigger: "blur" },
        {
            min: 0,
            max: 16,
            message: "密码长度应在0到16个字符之间",
            trigger: "blur",
        },
    ],
    confirmPassword: [
        { required: true, message: "请确认密码", trigger: "blur" },
        { validator: validateConfirmPassword, trigger: "blur" },
    ],
    phone: [
        { required: true, message: "手机号不能为空", trigger: "blur" },
        {
            pattern: /^[1][3-9][0-9]{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
        },
    ],
    email: [
        { required: true, message: "邮箱不能为空", trigger: "blur" },
        { type: "email", message: "邮箱格式不正确", trigger: "blur" },
    ],
};

const submitForm = async () => {
    try {
        await registerFormRef.value?.validate(async (valid: boolean) => {
            if (valid) {
                const url = "/API/register"; // 使用相对路径
                const data = {
                    username: registerForm.username,
                    password: registerForm.password,
                    email: registerForm.email,
                    phone: registerForm.phone,
                };
                try {
                    const response = await api.post(url, data); // 使用封装的 API 实例
                    if (response.status === 200) {
                        ElMessage({ message: "注册成功", type: "success" });
                    } else if (response.status === 409) {
                        ElMessage({ message: "用户名已存在", type: "error" });
                    } else {
                        ElMessage({
                            message:
                                "注册失败: " +
                                (response.data.message || "未知错误"),
                            type: "error",
                        });
                    }
                } catch (error: any) {
                    ElMessage({
                        message:
                            "注册失败: " +
                            (error.response
                                ? error.response.data
                                : "网络请求失败"),
                        type: "error",
                    });
                }
            } else {
                console.log("注册失败");
            }
        });
    } catch (error) {
        console.error("请求错误", error);
    }
};

const resetForm = () => {
    registerFormRef.value?.resetFields();
};

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref_key: "registerFormRef",
    ref: registerFormRef,
    model: registerForm,
    rules: rules,
    "status-icon": "",
    "label-width": "auto",
    class: "register-form"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: "用户名",
        prop: "username"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: registerForm.username,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((registerForm.username) = $event)),
            placeholder: "请输入用户名"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "密码",
        prop: "password"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: registerForm.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((registerForm.password) = $event)),
            type: "password",
            autocomplete: "off",
            placeholder: "请输入密码"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "重复密码",
        prop: "confirmPassword"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: registerForm.confirmPassword,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((registerForm.confirmPassword) = $event)),
            type: "password",
            autocomplete: "off",
            placeholder: "请确认密码"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "手机号",
        prop: "phone"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: registerForm.phone,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((registerForm.phone) = $event)),
            placeholder: "请输入手机号"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "邮箱",
        prop: "email"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: registerForm.email,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((registerForm.email) = $event)),
            placeholder: "请输入邮箱"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: submitForm
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("注册")
              ])),
              _: 1
            }),
            _createVNode(_component_el_button, { onClick: resetForm }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("重置")
              ])),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})