vue
<template>
    <div class="container">
        <h1 class="welcome-message">欢迎您，{{ nickname }}</h1>
        <p class="current-weather">
            当前天气：{{ weatherInfo.weather || "未知" }}，温度：{{
                weatherInfo.temperature || "未知"
            }}°C
        </p>
    </div>
</template>

<script>
import api from "@/api"; // 导入封装的 API 实例

export default {
    name: "home",
    data() {
        return {
            nickname: "",
            weatherInfo: {}, // 用于存储天气信息
        };
    },
    mounted() {
        this.fetchUserInfo();
        this.fetchWeatherInfo(); // 获取天气信息
    },
    methods: {
        async fetchUserInfo() {
            try {
                const token = sessionStorage.getItem("token");
                if (!token) {
                    throw new Error("Token not found");
                }
                const response = await api.get("/API/userInfo", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                this.nickname = response.data.userInfo.nickname;
            } catch (error) {
                console.error("获取用户信息失败", error);
            }
        },
        async fetchWeatherInfo() {
            try {
                const response = await fetch(
                    "https://api.vore.top/api/Weather"
                );
                const data = await response.json();
                if (data.code === 200) {
                    this.weatherInfo = data.data.tianqi; // 存储天气信息
                } else {
                    console.error("获取天气信息失败", data.msg);
                }
            } catch (error) {
                console.error("请求天气信息失败", error);
            }
        },
    },
};
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh; /* 使容器占满整个视口高度 */
    padding-top: 120px;
    position: relative; /* 为了定位时间 */
    font-family: Arial, sans-serif; /* 字体 */
}
.welcome-message {
    font-size: 2em; /* 字体大小 */
    margin: 0; /* 去掉默认边距 */
    text-align: center; /* 文本居中 */
}
.current-weather {
    position: absolute; /* 绝对定位 */
    top: 0px; /* 距离顶部20px */
    right: 20px; /* 距离右边20px */
    font-size: 1em; /* 字体大小 */
    padding: 10px; /* 内边距 */
    border-radius: 5px; /* 圆角 */
}
</style>
