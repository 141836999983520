vue
<template>
    <div class="add-dish-container">
        <el-form :model="newDish" label-width="100px" class="add-dish-form">
            <el-form-item label="菜品名称" prop="dishName">
                <el-input
                    v-model="newDish.dishName"
                    placeholder="请输入菜品名称"
                ></el-input>
            </el-form-item>
            <el-form-item label="描述" prop="description">
                <el-input
                    v-model="newDish.description"
                    placeholder="请输入描述"
                ></el-input>
            </el-form-item>
            <el-form-item label="分类" prop="category">
                <el-input
                    v-model="newDish.category"
                    placeholder="请输入菜品分类"
                ></el-input>
            </el-form-item>
            <el-form-item label="菜品图片" prop="imageURL">
                <el-upload
                    class="upload-demo"
                    action="https://connect.yzbhgzm.cn:9443/api/upload/file"
                    :show-file-list="false"
                    :on-success="handleImageUploadSuccess"
                    :before-upload="beforeImageUpload"
                    :data="{ folder: 'dishes' }"
                >
                    <el-button type="primary">上传菜品图片</el-button>
                </el-upload>
                <img
                    v-if="newDish.imageURL"
                    :src="newDish.imageURL"
                    class="dish-image-preview"
                />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitAddDish"
                    >确 定</el-button
                >
                <el-button @click="resetForm">取 消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import axios from "axios";
import { ElMessage } from "element-plus";
import api from "@/api"; // 导入封装的 API 实例
export default {
    name: "AddDish",
    data() {
        return {
            newDish: {
                dishName: "",
                description: "",
                category: "",
                imageURL: "", // 确保初始值为字符串
                creator: {
                    id: "", // 确保初始值为字符串
                    nickname: "", // 确保初始值为字符串
                },
                createdAt: "", // 创建时间
            },
            userId: "", // 确保初始值为字符串
            userNickname: "", // 确保初始值为字符串
        };
    },
    created() {
        this.fetchUserInfo(); // 获取用户信息
    },
    methods: {
        async fetchUserInfo() {
            const token = sessionStorage.getItem("token");
            if (token) {
                try {
                    const response = await api.get("API/userInfo", {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    this.userId = response.data.userInfo.id || ""; // 确保为字符串
                    this.userNickname = response.data.userInfo.nickname || ""; // 确保为字符串
                    this.newDish.creator.id = this.userId;
                    this.newDish.creator.nickname = this.userNickname;
                } catch (error) {
                    ElMessage.error("获取用户信息失败");
                    console.error("获取用户信息失败", error);
                }
            }
        },
        async submitAddDish() {
            if (!this.newDish.dishName) {
                ElMessage({ message: "菜品名称不能为空", type: "error" });
                return;
            }

            try {
                const response = await api.post("ADD/dish", {
                    ...this.newDish,
                    imageURL: this.newDish.imageURL || "", // 确保 imageURL 是字符串
                });
                if (response.status === 200) {
                    ElMessage({ message: "菜品添加成功", type: "success" });
                    this.resetForm(); // 重置表单
                } else {
                    ElMessage({
                        message:
                            "菜品添加失败: " +
                            (response.data.message || "未知错误"),
                        type: "error",
                    });
                }
            } catch (error) {
                ElMessage({
                    message:
                        "菜品添加失败: " +
                        (error.response ? error.response.data : "网络请求失败"),
                    type: "error",
                });
            }
        },
        handleImageUploadSuccess(response) {
            if (response && typeof response === "string") {
                this.newDish.imageURL = response; // 更新菜品的图片 URL
            } else {
                ElMessage.error("上传成功，但未能获取菜品图片 URL");
            }
        },
        beforeImageUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                ElMessage.error("上传的图片大小不能超过 2MB!");
            }
            return isLt2M;
        },
        resetForm() {
            this.newDish = {
                dishName: "",
                description: "",
                category: "", // 重置分类字段
                imageURL: "",
                creator: {
                    id: this.userId || "", // 确保为字符串
                    nickname: this.userNickname || "", // 确保为字符串
                },
            };
        },
    },
};
</script>

<style scoped>
.add-dish-container {
    padding: 20px;
}
.add-dish-form {
    max-width: 600px;
    margin: 0 auto;
}
.dish-image-preview {
    width: 100px; /* 设置预览图片的宽度 */
    height: auto; /* 自适应高度 */
    margin-top: 10px; /* 与上传按钮之间的间距 */
}
</style>
