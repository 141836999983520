vue
<template>
    <div>
        <!-- 搜索功能 -->
        <div style="margin-bottom: 20px">
            <el-input
                v-model="searchQuery"
                placeholder="输入菜品名称或ID"
                style="width: 300px; margin-right: 10px"
            ></el-input>
            <el-button
                type="primary"
                :icon="Search"
                @click="searchAnnouncements"
                >搜索</el-button
            >
            <el-button type="warning" :icon="Delete" @click="handleBatchDelete">
                批量删除
            </el-button>
        </div>
        <el-table
            ref="multipleTableRef"
            style="width: 100%"
            border
            :header-cell-style="{ backgroundColor: '#f2f2f2', color: '#333' }"
            :data="filteredtableData"
            @selection-change="handleSelectionChange"
            row-key="dishID"
        >
            <el-table-column type="selection" width="55" />
            <el-table-column label="ID" width="110">
                <template #default="scope">{{ scope.row.dishID }}</template>
            </el-table-column>
            <el-table-column property="dishName" label="菜品名称" width="160" />
            <el-table-column label="头像" width="180">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <img
                            :src="scope.row.imageURL"
                            alt="头像"
                            class="avatar-image2"
                        />
                    </div>
                </template>
            </el-table-column>
            <el-table-column property="description" label="描述" width="160" />
            <el-table-column property="category" label="分类" width="160" />
            <el-table-column label="状态" width="180">
                <template #default="scope">
                    <el-switch
                        v-model="scope.row.status"
                        :active-value="'show'"
                        :inactive-value="'hide'"
                        @change="handleStatusChange(scope.row)"
                        style="
                            --el-switch-on-color: #13ce66;
                            --el-switch-off-color: #ff4949;
                        "
                    />
                    <span style="margin-left: 10px">
                        {{ scope.row.status === "show" ? "显示" : "隐藏" }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="创建日期" width="220">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <el-icon><timer /></el-icon>
                        <span style="margin-left: 10px">{{
                            formatDate(scope.row.createdAt)
                        }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                property="creator.nickname"
                label="创建人"
                width="180"
            />
            <el-table-column label="操作" width="310">
                <template #default="scope">
                    <el-button size="small" @click="editDish(scope.row)"
                        >编辑</el-button
                    >
                    <el-button
                        size="small"
                        type="danger"
                        @click="confirmDelete(scope.row.dishID)"
                        >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <!-- 编辑菜品模态框 -->
        <el-dialog
            v-model="dialogVisible"
            title="编辑菜品"
            :close-on-click-modal="false"
        >
            <el-form
                :model="currentDish"
                ref="editForm"
                style="margin-left: 5%"
            >
                <el-form-item label="头像:" prop="imageURL">
                    <div class="avatar-upload-container">
                        <el-upload
                            class="upload-demo"
                            action="https://connect.yzbhgzm.cn:9443/api/upload/file"
                            :data="{ folder: 'dish' }"
                            :on-success="handleUploadSuccess"
                            :show-file-list="false"
                            accept="image/*"
                        >
                            <img
                                v-if="currentDish?.imageURL"
                                :src="currentDish.imageURL"
                                alt="头像"
                                class="avatar-image1"
                            />
                            <div
                                v-if="!currentDish?.imageURL"
                                class="avatar-placeholder"
                            >
                                <span>上传头像</span>
                            </div>
                            <div v-if="currentDish?.imageURL" class="overlay">
                                <span>修改图片</span>
                            </div>
                        </el-upload>
                    </div>
                </el-form-item>
                <el-form-item label="名称:" prop="dishName">
                    <el-input v-model="currentDish.dishName" />
                </el-form-item>
                <el-form-item label="描述:" prop="description">
                    <el-input v-model="currentDish.description" />
                </el-form-item>
                <el-form-item label="分类:" prop="category">
                    <el-input v-model="currentDish.category" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="updateDish">确 定</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import api from "@/api"; // 导入封装的 API 实例
import { Timer, Search, Delete } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

interface Dish {
    dishID: number;
    imageURL: string; // 头像 URL
    dishName: string;
    description: string;
    category: string;
    cookingMethod: string;
    status: string; // 修改为 string 类型
    createdAt: string;
    updatedAt: string;
    creator: string;
}

const tableData = ref<Dish[]>([]);
const filteredtableData = ref<Dish[]>([]);
const searchQuery = ref(""); // 搜索内容
const selectedDishIDs = ref<number[]>([]); // 存储选中的菜品 ID
const dialogVisible = ref(false); // 控制模态框显示，初始值为 false
const currentDish = ref<Dish>({} as Dish); // 初始化为一个空对象

const fetchDish = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        const response = await api.get("/API/Dish", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        tableData.value =
            response.data.map((dish: Dish) => ({
                ...dish,
            })) || [];
        filteredtableData.value = tableData.value; // 初始化过滤后的公告列表
    } catch (error) {
        console.error("获取公告信息失败", error);
    }
};

// 搜索公告
const searchAnnouncements = () => {
    if (searchQuery.value.trim() === "") {
        filteredtableData.value = tableData.value; // 如果搜索框为空，显示所有公告
    } else {
        filteredtableData.value = tableData.value.filter((dish) => {
            return (
                dish.dishID.toString().includes(searchQuery.value) ||
                dish.dishName.includes(searchQuery.value)
            );
        });
    }
};

// 格式化日期
const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
};

// 更新菜品状态
const handleStatusChange = async (dish: Dish) => {
    const newStatus = dish.status === "show" ? "show" : "hide"; // 切换状态
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        const response = await api.put(
            `/PUT/dish/status/${dish.dishID}`,
            { status: newStatus },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        // 更新本地状态
        dish.status = newStatus; // 直接更新本地状态
        ElMessage.success(response.data); // 显示成功消息
    } catch (error) {
        console.error("更新菜品状态失败", error);
        ElMessage.error("更新菜品状态失败");
    }
};

const handleSelectionChange = (selection: Dish[]) => {
    selectedDishIDs.value = selection.map((dish) => dish.dishID);
};

const confirmDelete = (dishID: string) => {
    ElMessageBox.confirm("确定要删除该用户吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
    })
        .then(async () => {
            await handleDelete(dishID);
        })
        .catch(() => {
            ElMessage.info("取消删除");
        });
};

// 删除用户
const handleDelete = async (dishID: string) => {
    try {
        await api.delete(`/DELETE/dish/${dishID}`); // 使用封装的 API 实例
        ElMessage.success(`菜品ID:${dishID} 已删除`);
        // 重新获取数据以更新表格
        await fetchDish(); // 重新获取用户数据
    } catch (error) {
        ElMessage.error("删除用户失败");
        console.error("删除用户失败:", error);
    }
};

// 批量删除
const handleBatchDelete = async () => {
    if (selectedDishIDs.value.length === 0) {
        ElMessage.warning("请至少选择一个菜品进行删除");
        return;
    }
    ElMessageBox.confirm("确定要删除选中的菜品吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
    })
        .then(async () => {
            try {
                await api.delete(`/DELETE/dishes`, {
                    data: selectedDishIDs.value,
                });
                ElMessage.success("选中的菜品已删除");
                await fetchDish(); // 重新获取数据
            } catch (error) {
                ElMessage.error("删除菜品失败");
                console.error("删除菜品失败:", error);
            }
        })
        .catch(() => {
            ElMessage.info("取消删除");
        });
};

// 编辑菜品
const editDish = (dish: Dish) => {
    console.log("Editing dish:", dish); // 调试信息
    currentDish.value = { ...dish }; // 复制菜品数据
    dialogVisible.value = true; // 显示模态框
};

// 处理图片上传成功
const handleUploadSuccess = (response: any) => {
    if (response) {
        currentDish.value.imageURL = response; // 更新菜品的头像 URL
    }
    console.log(response);
};

// 更新菜品信息
const updateDish = async () => {
    if (!currentDish.value) return; // 这里可以保持不变
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        const response = await api.put(
            `/PUT/dish/${currentDish.value.dishID}`,
            currentDish.value,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        ElMessage.success(response.data); // 显示成功消息
        dialogVisible.value = false; // 关闭模态框
        await fetchDish(); // 重新获取数据
    } catch (error) {
        console.error("更新菜品信息失败", error);
        ElMessage.error("更新菜品信息失败");
    }
};

onMounted(fetchDish);
</script>

<style>
.avatar-image2 {
    margin-left: 25%;
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
}
.avatar-image1 {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid #eaeaea;
}

.avatar-placeholder {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    border: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
}

.avatar-upload-container {
    position: relative;
    display: inline-block;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    z-index: 2;
    cursor: pointer;
    opacity: 0; /* 默认隐藏 */
    transition: opacity 0.3s;
}

.avatar-upload-container:hover .overlay {
    width: 80px;
    height: 80px;
    opacity: 1; /* 鼠标悬停时显示蒙版 */
}
</style>
