vue
<template>
    <div style="position: relative; min-height: 400px">
        <el-menu
            default-active="/home"
            class="el-menu-vertical-demo"
            :collapse="isCollapse"
            @open="handleOpen"
            @close="handleClose"
            active-text-color="#ffd04b"
            background-color="#545c64"
            text-color="#fff"
            style="height: 100%; padding-top: 10px"
        >
            <el-menu-item index="/" @click="navigateTo('/')">
                <el-icon><icon-menu /></el-icon>
                <template #title>首页</template>
            </el-menu-item>
            <el-sub-menu index="/user">
                <template #title>
                    <el-icon><Avatar /></el-icon>
                    <span>个人中心</span>
                </template>
                <el-menu-item index="/personal" @click="navigateTo('/personal')"
                    >个人信息</el-menu-item
                >
                <el-menu-item
                    index="/usersetting"
                    @click="navigateTo('/usersetting')"
                    >用户设置</el-menu-item
                >
            </el-sub-menu>
            <el-sub-menu index="/users">
                <template #title>
                    <el-icon><User /></el-icon>
                    <span>用户中心</span>
                </template>
                <el-menu-item index="/userlist" @click="navigateTo('/userlist')"
                    >用户列表</el-menu-item
                >
                <el-menu-item index="/adduser" @click="navigateTo('/adduser')"
                    >添加用户</el-menu-item
                >
            </el-sub-menu>
            <el-sub-menu index="/system-announcement">
                <template #title>
                    <el-icon><Management /></el-icon>
                    <span>系统公告</span>
                </template>
                <el-menu-item
                    index="/announcement"
                    @click="navigateTo('/announcement')"
                    >公告列表</el-menu-item
                >
                <el-menu-item
                    index="/addannouncement"
                    @click="navigateTo('/addannouncement')"
                    >添加公告</el-menu-item
                >
            </el-sub-menu>
            <el-sub-menu index="/dish">
                <template #title>
                    <el-icon><Dish /></el-icon>
                    <span>菜品管理</span>
                </template>
                <el-menu-item index="/dish" @click="navigateTo('/dish')"
                    >菜品列表</el-menu-item
                >
                <el-menu-item index="/adddish" @click="navigateTo('/adddish')"
                    >添加菜品</el-menu-item
                >
            </el-sub-menu>
            <el-menu-item index="/settings" @click="navigateTo('/settings')">
                <el-icon><Setting /></el-icon>
                <template #title>设置</template>
            </el-menu-item>
            <div style="position: absolute; bottom: 10px; left: 1px">
                <el-button
                    @click="toggleCollapse"
                    style="
                        border: none;
                        background-color: #545c64;
                        color: white;
                    "
                >
                    <el-icon>
                        <template v-if="isCollapse">
                            <Fold />
                        </template>
                        <template v-else>
                            <Expand />
                        </template>
                    </el-icon>
                </el-button>
            </div>
        </el-menu>
    </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import {
    Avatar,
    Menu as IconMenu,
    Setting,
    User,
    Expand,
    Fold,
    Dish,
    Management,
} from "@element-plus/icons-vue";

const router = useRouter();
const isCollapse = ref(false);

const toggleCollapse = () => {
    isCollapse.value = !isCollapse.value;
    requestAnimationFrame(() => {
        // 可以在这里添加额外的动画逻辑
    });
};

const navigateTo = (path: string) => {
    router.push(path);
};

const handleOpen = (key: string, keyPath: string[]) => {
    console.log(key, keyPath);
};

const handleClose = (key: string, keyPath: string[]) => {
    console.log(key, keyPath);
};
</script>

<style>
.el-menu-vertical-demo {
    transition: all 0.3s ease; /* 添加过渡效果 */
}
</style>
