<template>
    <el-container class="login-container">
        <el-header>
            <h1 class="login-title">欢迎登录</h1>
        </el-header>
        <el-main>
            <el-card class="login-card" shadow="never">
                <el-form
                    :model="form"
                    ref="formRef"
                    label-width="0px"
                    @submit.prevent="handleLogin"
                    class="login-form"
                >
                    <el-form-item prop="username">
                        <el-input
                            v-model="form.username"
                            placeholder="请输入用户名"
                            prefix-icon="el-icon-user"
                            clearable
                            class="login-input"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input
                            type="password"
                            v-model="form.password"
                            placeholder="请输入密码"
                            prefix-icon="el-icon-lock"
                            @keyup.enter="handleLogin"
                            clearable
                            class="login-input"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            type="primary"
                            :loading="loading"
                            @click="handleLogin"
                            class="login-button"
                        >
                            登录
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <div class="login-footer">
                            <span>还没有账号？</span>
                            <el-button type="text" class="register-button"
                                >注册</el-button
                            >
                        </div>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import api from "@/api"; // 导入封装的 API 实例

export default {
    name: "UserLogin",
    setup() {
        const form = ref({
            username: "",
            password: "",
        });
        const loading = ref(false);
        const router = useRouter();

        const handleLogin = async () => {
            if (!form.value.username || !form.value.password) {
                alert("用户名和密码不能为空");
                return;
            }
            loading.value = true; // 开始加载
            try {
                const response = await api.post("/API/login", form.value);

                const token = response.data.token;
                const role = response.data.role;

                if (role === "SUPERADMIN") {
                    // 如果角色为超级管理员，跳转到主页
                    router.push("/");
                    sessionStorage.setItem("token", token);
                } else {
                    alert("权限不足");
                }
            } catch (error) {
                // 根据后端返回的错误信息进行处理
                if (error.response) {
                    const errorMessage = error.response.data;
                    alert(errorMessage); // 显示后端返回的错误信息
                } else {
                    console.error(error);
                    alert("登录失败，请重试");
                }
            } finally {
                loading.value = false; // 结束加载
            }
        };

        return {
            form,
            handleLogin,
            loading,
        };
    },
};
</script>

<style scoped>
.login-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #1cbbb4, #0081ff); /* 渐变背景 */
}
.login-title {
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 20px;
}
.login-card {
    border-radius: 10px;
    padding: 40px;
    width: 400px;
    box-shadow: none;
    background-color: #fff;
}
.login-form {
    max-width: 100%;
    width: 100%;
}
.el-form-item {
    margin-bottom: 25px; /* 增加间距 */
}
.login-input {
    border-radius: 4px;
    transition: border-color 0.3s, box-shadow 0.3s;
}
.login-input:hover {
    border-color: #409eff;
}
.login-button {
    width: 100%;
    border-radius: 4px;
    padding: 10px;
    font-size: 18px; /* 增加字体大小 */
    background-color: #409eff;
    transition: background-color 0.3s;
}
.login-button:hover {
    background-color: #66b1ff;
}
.login-footer {
    text-align: center;
    margin-top: 20px;
}
.register-button {
    color: #409eff;
    text-decoration: underline;
}
.register-button:hover {
    color: #66b1ff;
}
.el-input__inner {
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    transition: border-color 0.3s, box-shadow 0.3s;
}
.el-input__inner:focus {
    border-color: #409eff;
    box-shadow: 0 0 0 2px rgba(64, 158, 255, 0.5);
}
/* 若依框架特有的图标样式 */
.el-input-group__prepend {
    background-color: #f5f7fa;
    color: #909399;
    border: 1px solid #dcdfe6;
    border-right: none;
    border-radius: 4px 0 0 4px;
    padding: 0 10px;
}
.el-input-group__append {
    background-color: #f5f7fa;
    color: #909399;
    border: 1px solid #dcdfe6;
    border-left: none;
    border-radius: 0 4px 4px 0;
    padding: 0 10px;
}
</style>
