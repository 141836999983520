import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header-content" }
const _hoisted_2 = { class: "user-settings" }
const _hoisted_3 = { class: "el-dropdown-link" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "Settingicon" }

import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

import api from "@/api"; // 导入封装的 API 实例
import { Setting } from "@element-plus/icons-vue";
import { ElMessageBox } from "element-plus"; // 引入 ElMessageBox


export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  setup(__props) {

const router = useRouter();
const userAvatar = ref(""); // 存储用户头像 URL
const token = sessionStorage.getItem("token"); // 获取 token

const navigateTo = (path: string) => {
    router.push(path);
};

// 请求用户信息并获取头像
const fetchUserInfo = async () => {
    try {
        const response = await api.get("/API/userInfo", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        // 假设返回的响应对象中包含用户头像信息
        userAvatar.value = response.data.userInfo.avatarUrl || ""; // 根据实际返回字段调整
    } catch (error) {
        console.error("Failed to fetch user info", error);
    }
};

// 页面挂载时请求用户信息
onMounted(() => {
    if (token) {
        fetchUserInfo();
    } else {
        console.log("No token found");
    }
});

// 确认退出逻辑
const confirmLogout = () => {
    ElMessageBox.confirm("确定要退出吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
    })
        .then(() => {
            logout(); // 用户确认退出后调用 logout 方法
        })
        .catch(() => {
            // 用户取消退出，不做任何操作
            console.log("取消退出");
        });
};

// 退出逻辑
const logout = () => {
    // 清除 token 和缓存
    sessionStorage.removeItem("token");
    localStorage.clear(); // 如果有使用 localStorage 也清除
    // 导航到登录界面
    navigateTo("/UserLogin");
};

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_header = _resolveComponent("el-header")!

  return (_openBlock(), _createBlock(_component_el_header, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[5] || (_cache[5] = _createElementVNode("h2", { style: {"text-align":"center","padding-left":"45%","color":"white"} }, " 小 程 序 后 台 管 理 系 统 ", -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("img", {
              src: userAvatar.value,
              alt: "User Avatar",
              class: "user-avatar"
            }, null, 8, _hoisted_4)
          ]),
          _createVNode(_component_el_dropdown, null, {
            dropdown: _withCtx(() => [
              _createVNode(_component_el_dropdown_menu, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_dropdown_item, {
                    index: "/personal",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (navigateTo('/personal')))
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" 个人中心 ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_dropdown_item, {
                    index: "/usersetting",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (navigateTo('/usersetting')))
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" 账号设置 ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_dropdown_item, { onClick: confirmLogout }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("退出")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_5, [
                _createVNode(_component_el_icon, { style: {"color":"white","font-size":"25px"} }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Setting))
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})