<template>
    <div>
        <!-- 搜索栏 -->
        <div style="margin-bottom: 20px; display: flex; align-items: center">
            <el-input
                v-model="searchQuery"
                placeholder="请输入用户ID或昵称"
                style="width: 300px"
            ></el-input>
            <el-button
                type="primary"
                @click="searchUsers"
                :icon="Search"
                style="margin-left: 10px"
            >
                搜索
            </el-button>
            <el-button
                type="success"
                @click="exportToExcel"
                style="margin-left: 10px"
            >
                导出为Excel
            </el-button>
        </div>
        <el-table :data="filteredData" style="width: 100%">
            <el-table-column prop="id" label="ID" width="120" />
            <el-table-column label="头像" width="180">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <img
                            :src="scope.row.avatarUrl"
                            alt="头像"
                            style="
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                object-fit: cover;
                                object-position: center;
                            "
                        />
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="昵称" width="180">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span>{{ scope.row.nickname }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="联系方式" width="180">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span>{{ scope.row.phone }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="性别" width="180">
                <template #default="scope">
                    <el-popover
                        effect="light"
                        trigger="hover"
                        placement="top"
                        width="auto"
                    >
                        <template #default>
                            <div>性别: {{ scope.row.gender }}</div>
                        </template>
                        <template #reference>
                            <el-tag>{{ scope.row.gender }}</el-tag>
                        </template>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="角色" width="180">
                <template #default="scope">
                    <el-popover
                        effect="light"
                        trigger="hover"
                        placement="top"
                        width="auto"
                    >
                        <template #default>
                            <div>角色: {{ getRoleName(scope.row.role) }}</div>
                            <div>等级: {{ scope.row.level }}</div>
                        </template>
                        <template #reference>
                            <el-tag>{{ getRoleName(scope.row.role) }}</el-tag>
                        </template>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="状态" width="180">
                <template #default="scope">
                    <el-switch
                        v-model="scope.row.status"
                        :active-value="'Active'"
                        :inactive-value="'Frozen'"
                        style="
                            --el-switch-on-color: #13ce66;
                            --el-switch-off-color: #ff4949;
                        "
                        @change="
                            updateUserStatus(scope.row.id, scope.row.status)
                        "
                    />
                    <span style="margin-left: 10px">
                        {{ scope.row.status === "Active" ? "正常" : "封禁" }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="创建日期" width="220">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <el-icon><timer /></el-icon>
                        <span style="margin-left: 10px">{{
                            formatDate(scope.row.createdAt)
                        }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="240">
                <template #default="scope">
                    <div style="display: flex">
                        <el-button
                            size="small"
                            type="primary"
                            @click="openEditDialog(scope.row)"
                        >
                            设置
                        </el-button>
                        <el-button
                            size="small"
                            type="danger"
                            @click="confirmDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- 编辑用户信息对话框 -->
        <el-dialog title="编辑用户信息" v-model="editDialogVisible" width="30%">
            <el-form :model="editForm" ref="editFormRef" label-width="100px">
                <el-form-item
                    label="昵称"
                    prop="nickname"
                    :rules="[
                        {
                            required: true,
                            message: '请输入昵称',
                            trigger: 'blur',
                        },
                    ]"
                >
                    <el-input
                        v-model="editForm.nickname"
                        placeholder="请输入昵称"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="性别"
                    prop="gender"
                    :rules="[
                        {
                            required: true,
                            message: '请选择性别',
                            trigger: 'change',
                        },
                    ]"
                >
                    <el-select
                        v-model="editForm.gender"
                        placeholder="请选择性别"
                    >
                        <el-option label="男" value="男"></el-option>
                        <el-option label="女" value="女"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="等级"
                    prop="level"
                    :rules="[
                        {
                            required: true,
                            message: '请选择等级',
                            trigger: 'change',
                        },
                    ]"
                >
                    <el-select
                        v-model="editForm.level"
                        placeholder="请选择等级"
                    >
                        <el-option
                            v-for="level in [1, 2, 3, 4, 5]"
                            :key="level"
                            :label="level"
                            :value="level"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="角色"
                    prop="role"
                    :rules="[
                        {
                            required: true,
                            message: '请选择角色',
                            trigger: 'change',
                        },
                    ]"
                >
                    <el-select v-model="editForm.role" placeholder="请选择角色">
                        <el-option
                            label="超级管理员"
                            value="SUPERADMIN"
                        ></el-option>
                        <el-option label="管理员" value="ADMIN"></el-option>
                        <el-option label="用户" value="USER"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="地址"
                    prop="address"
                    :rules="[
                        {
                            required: true,
                            message: '请输入地址',
                            trigger: 'blur',
                        },
                    ]"
                >
                    <el-input
                        v-model="editForm.address"
                        placeholder="请输入地址"
                    ></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="updateUser">确 定</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script lang="ts" setup>
import { Timer, Search } from "@element-plus/icons-vue";
import { ref, onMounted } from "vue";
import api from "@/api"; // 导入封装的 API 实例
import { ElMessage, ElMessageBox } from "element-plus";
import * as XLSX from "xlsx";

interface User {
    id: string;
    avatarUrl: string; // 头像 URL
    nickname: string;
    phone: string;
    role: string;
    gender: string;
    level: number; // 修改为 number 类型
    createdAt: string;
    updatedAt: string;
    address: string;
}

const tableData = ref<User[]>([]);
const filteredData = ref<User[]>([]); // 初始化为一个空数组
const searchQuery = ref<string>("");
const editDialogVisible = ref(false); // 控制对话框的显示
const editForm = ref<User>({
    id: "",
    avatarUrl: "",
    nickname: "",
    phone: "",
    role: "",
    gender: "",
    level: 1, // 默认等级
    createdAt: "",
    updatedAt: "",
    address: "",
});

// 获取用户数据
const fetchData = async () => {
    try {
        const response = await api.get("/API/usersInfo"); // 使用封装的 API 实例
        if (Array.isArray(response.data.users)) {
            tableData.value = response.data.users; // 确保这是一个数组
            filteredData.value = tableData.value; // 初始化 filteredData 为所有数据
        } else {
            console.error("返回的数据不是数组:");
        }
    } catch (error) {
        console.error("获取用户信息失败:", error);
    }
};

// 组件挂载时获取数据
onMounted(() => {
    fetchData();
});

// 打开编辑对话框并填充数据
const openEditDialog = (user: User) => {
    editForm.value = { ...user }; // 复制用户信息到编辑表单
    editDialogVisible.value = true; // 显示对话框
};

// 更新用户信息
const updateUser = async () => {
    try {
        const userId = editForm.value.id; // 获取用户 ID
        await api.put(`/UPDATE/users/${userId}`, editForm.value); // 使用封装的 API 实例
        ElMessage.success("用户信息更新成功");
        editDialogVisible.value = false; // 关闭对话框
        await fetchData(); // 重新获取用户数据
    } catch (error) {
        ElMessage.error("更新用户信息失败");
        console.error("更新用户信息失败:", error);
    }
};

// 搜索用户
const searchUsers = () => {
    try {
        if (!searchQuery.value) {
            filteredData.value = tableData.value; // 如果没有搜索条件，返回所有数据
        } else {
            const query = searchQuery.value.toLowerCase(); // 这里确保 searchQuery 是字符串
            filteredData.value = tableData.value.filter(
                (user) =>
                    String(user.id).includes(query) ||
                    (user.nickname &&
                        user.nickname.toLowerCase().includes(query))
            );
        }
    } catch (error) {
        ElMessage.error("搜索过程中发生错误");
        console.error("搜索错误:", error);
    }
};

// 获取角色名称
const getRoleName = (role: string) => {
    switch (role) {
        case "SUPERADMIN":
            return "超级管理员";
        case "ADMIN":
            return "管理员";
        case "USER":
            return "普通用户";
        default:
            return "未知角色";
    }
};

// 格式化日期
const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
};

// 确认删除用户
const confirmDelete = (userId: string) => {
    ElMessageBox.confirm("确定要删除该用户吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
    })
        .then(async () => {
            await handleDelete(userId);
        })
        .catch(() => {
            ElMessage.info("取消删除");
        });
};

// 删除用户
const handleDelete = async (userId: string) => {
    try {
        await api.delete(`/DELETE/user/${userId}`); // 使用封装的 API 实例
        ElMessage.success(`用户ID:${userId} 已删除`);
        // 重新获取数据以更新表格
        await fetchData(); // 重新获取用户数据
    } catch (error) {
        ElMessage.error("删除用户失败");
        console.error("删除用户失败:", error);
    }
};

// 更新用户状态
const updateUserStatus = async (userId: string, status: string) => {
    try {
        const response = await api.put(`/UPDATE/status/${userId}`, null, {
            params: { status: status },
        }); // 使用封装的 API 实例
        ElMessage.success(response.data);
        // 重新获取数据以更新表格
        await fetchData(); // 重新获取用户数据
    } catch (error) {
        ElMessage.error("更新用户状态失败");
        console.error("更新用户状态失败:", error);
    }
};

// 导出为Excel
const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "用户列表");
    XLSX.writeFile(workbook, "用户列表.xlsx");
};
</script>

<style scoped>
/* 可以添加你自己的样式 */
</style>
