<template>
    <el-header>
        <div class="header-content">
            <h2 style="text-align: center; padding-left: 45%; color: white">
                小 程 序 后 台 管 理 系 统
            </h2>
            <div class="user-settings">
                <span class="el-dropdown-link">
                    <!-- 动态绑定头像 -->
                    <img
                        :src="userAvatar"
                        alt="User Avatar"
                        class="user-avatar"
                    />
                </span>
                <el-dropdown>
                    <span class="Settingicon">
                        <el-icon style="color: white; font-size: 25px">
                            <Setting />
                        </el-icon>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item
                                index="/personal"
                                @click="navigateTo('/personal')"
                            >
                                个人中心
                            </el-dropdown-item>
                            <el-dropdown-item
                                index="/usersetting"
                                @click="navigateTo('/usersetting')"
                            >
                                账号设置
                            </el-dropdown-item>
                            <el-dropdown-item @click="confirmLogout"
                                >退出</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
    </el-header>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

import api from "@/api"; // 导入封装的 API 实例
import { Setting } from "@element-plus/icons-vue";
import { ElMessageBox } from "element-plus"; // 引入 ElMessageBox

const router = useRouter();
const userAvatar = ref(""); // 存储用户头像 URL
const token = sessionStorage.getItem("token"); // 获取 token

const navigateTo = (path: string) => {
    router.push(path);
};

// 请求用户信息并获取头像
const fetchUserInfo = async () => {
    try {
        const response = await api.get("/API/userInfo", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        // 假设返回的响应对象中包含用户头像信息
        userAvatar.value = response.data.userInfo.avatarUrl || ""; // 根据实际返回字段调整
    } catch (error) {
        console.error("Failed to fetch user info", error);
    }
};

// 页面挂载时请求用户信息
onMounted(() => {
    if (token) {
        fetchUserInfo();
    } else {
        console.log("No token found");
    }
});

// 确认退出逻辑
const confirmLogout = () => {
    ElMessageBox.confirm("确定要退出吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
    })
        .then(() => {
            logout(); // 用户确认退出后调用 logout 方法
        })
        .catch(() => {
            // 用户取消退出，不做任何操作
            console.log("取消退出");
        });
};

// 退出逻辑
const logout = () => {
    // 清除 token 和缓存
    sessionStorage.removeItem("token");
    localStorage.clear(); // 如果有使用 localStorage 也清除
    // 导航到登录界面
    navigateTo("/UserLogin");
};
</script>

<style scoped>
.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    background-color: #545c64;
}
.user-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 20px;
    margin-top: 10px;
    object-fit: cover; /* 保证图片不变形，按比例裁剪 */
    object-position: center; /* 保证图片居中显示 */
}
.Settingicon {
    margin-right: 20px;
    margin-top: 23px;
}
</style>
