import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"20px"} }
const _hoisted_2 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_3 = ["src"]
const _hoisted_4 = { style: {"margin-left":"10px"} }
const _hoisted_5 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_6 = { style: {"margin-left":"10px"} }
const _hoisted_7 = { class: "avatar-upload-container" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  class: "avatar-placeholder"
}
const _hoisted_10 = {
  key: 2,
  class: "overlay"
}

import { ref, onMounted } from "vue";
import api from "@/api"; // 导入封装的 API 实例
import { Timer, Search, Delete } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

interface Dish {
    dishID: number;
    imageURL: string; // 头像 URL
    dishName: string;
    description: string;
    category: string;
    cookingMethod: string;
    status: string; // 修改为 string 类型
    createdAt: string;
    updatedAt: string;
    creator: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'dish',
  setup(__props) {

const tableData = ref<Dish[]>([]);
const filteredtableData = ref<Dish[]>([]);
const searchQuery = ref(""); // 搜索内容
const selectedDishIDs = ref<number[]>([]); // 存储选中的菜品 ID
const dialogVisible = ref(false); // 控制模态框显示，初始值为 false
const currentDish = ref<Dish>({} as Dish); // 初始化为一个空对象

const fetchDish = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        const response = await api.get("/API/Dish", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        tableData.value =
            response.data.map((dish: Dish) => ({
                ...dish,
            })) || [];
        filteredtableData.value = tableData.value; // 初始化过滤后的公告列表
    } catch (error) {
        console.error("获取公告信息失败", error);
    }
};

// 搜索公告
const searchAnnouncements = () => {
    if (searchQuery.value.trim() === "") {
        filteredtableData.value = tableData.value; // 如果搜索框为空，显示所有公告
    } else {
        filteredtableData.value = tableData.value.filter((dish) => {
            return (
                dish.dishID.toString().includes(searchQuery.value) ||
                dish.dishName.includes(searchQuery.value)
            );
        });
    }
};

// 格式化日期
const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
};

// 更新菜品状态
const handleStatusChange = async (dish: Dish) => {
    const newStatus = dish.status === "show" ? "show" : "hide"; // 切换状态
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        const response = await api.put(
            `/PUT/dish/status/${dish.dishID}`,
            { status: newStatus },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        // 更新本地状态
        dish.status = newStatus; // 直接更新本地状态
        ElMessage.success(response.data); // 显示成功消息
    } catch (error) {
        console.error("更新菜品状态失败", error);
        ElMessage.error("更新菜品状态失败");
    }
};

const handleSelectionChange = (selection: Dish[]) => {
    selectedDishIDs.value = selection.map((dish) => dish.dishID);
};

const confirmDelete = (dishID: string) => {
    ElMessageBox.confirm("确定要删除该用户吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
    })
        .then(async () => {
            await handleDelete(dishID);
        })
        .catch(() => {
            ElMessage.info("取消删除");
        });
};

// 删除用户
const handleDelete = async (dishID: string) => {
    try {
        await api.delete(`/DELETE/dish/${dishID}`); // 使用封装的 API 实例
        ElMessage.success(`菜品ID:${dishID} 已删除`);
        // 重新获取数据以更新表格
        await fetchDish(); // 重新获取用户数据
    } catch (error) {
        ElMessage.error("删除用户失败");
        console.error("删除用户失败:", error);
    }
};

// 批量删除
const handleBatchDelete = async () => {
    if (selectedDishIDs.value.length === 0) {
        ElMessage.warning("请至少选择一个菜品进行删除");
        return;
    }
    ElMessageBox.confirm("确定要删除选中的菜品吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
    })
        .then(async () => {
            try {
                await api.delete(`/DELETE/dishes`, {
                    data: selectedDishIDs.value,
                });
                ElMessage.success("选中的菜品已删除");
                await fetchDish(); // 重新获取数据
            } catch (error) {
                ElMessage.error("删除菜品失败");
                console.error("删除菜品失败:", error);
            }
        })
        .catch(() => {
            ElMessage.info("取消删除");
        });
};

// 编辑菜品
const editDish = (dish: Dish) => {
    console.log("Editing dish:", dish); // 调试信息
    currentDish.value = { ...dish }; // 复制菜品数据
    dialogVisible.value = true; // 显示模态框
};

// 处理图片上传成功
const handleUploadSuccess = (response: any) => {
    if (response) {
        currentDish.value.imageURL = response; // 更新菜品的头像 URL
    }
    console.log(response);
};

// 更新菜品信息
const updateDish = async () => {
    if (!currentDish.value) return; // 这里可以保持不变
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        const response = await api.put(
            `/PUT/dish/${currentDish.value.dishID}`,
            currentDish.value,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        ElMessage.success(response.data); // 显示成功消息
        dialogVisible.value = false; // 关闭模态框
        await fetchDish(); // 重新获取数据
    } catch (error) {
        console.error("更新菜品信息失败", error);
        ElMessage.error("更新菜品信息失败");
    }
};

onMounted(fetchDish);

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_input, {
        modelValue: searchQuery.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
        placeholder: "输入菜品名称或ID",
        style: {"width":"300px","margin-right":"10px"}
      }, null, 8, ["modelValue"]),
      _createVNode(_component_el_button, {
        type: "primary",
        icon: _unref(Search),
        onClick: searchAnnouncements
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode("搜索")
        ])),
        _: 1
      }, 8, ["icon"]),
      _createVNode(_component_el_button, {
        type: "warning",
        icon: _unref(Delete),
        onClick: handleBatchDelete
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createTextVNode(" 批量删除 ")
        ])),
        _: 1
      }, 8, ["icon"])
    ]),
    _createVNode(_component_el_table, {
      ref: "multipleTableRef",
      style: {"width":"100%"},
      border: "",
      "header-cell-style": { backgroundColor: '#f2f2f2', color: '#333' },
      data: filteredtableData.value,
      onSelectionChange: handleSelectionChange,
      "row-key": "dishID"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          type: "selection",
          width: "55"
        }),
        _createVNode(_component_el_table_column, {
          label: "ID",
          width: "110"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.dishID), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          property: "dishName",
          label: "菜品名称",
          width: "160"
        }),
        _createVNode(_component_el_table_column, {
          label: "头像",
          width: "180"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: scope.row.imageURL,
                alt: "头像",
                class: "avatar-image2"
              }, null, 8, _hoisted_3)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          property: "description",
          label: "描述",
          width: "160"
        }),
        _createVNode(_component_el_table_column, {
          property: "category",
          label: "分类",
          width: "160"
        }),
        _createVNode(_component_el_table_column, {
          label: "状态",
          width: "180"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_switch, {
              modelValue: scope.row.status,
              "onUpdate:modelValue": ($event: any) => ((scope.row.status) = $event),
              "active-value": 'show',
              "inactive-value": 'hide',
              onChange: ($event: any) => (handleStatusChange(scope.row)),
              style: {"--el-switch-on-color":"#13ce66","--el-switch-off-color":"#ff4949"}
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]),
            _createElementVNode("span", _hoisted_4, _toDisplayString(scope.row.status === "show" ? "显示" : "隐藏"), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "创建日期",
          width: "220"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_unref(Timer))
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_6, _toDisplayString(formatDate(scope.row.createdAt)), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          property: "creator.nickname",
          label: "创建人",
          width: "180"
        }),
        _createVNode(_component_el_table_column, {
          label: "操作",
          width: "310"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_button, {
              size: "small",
              onClick: ($event: any) => (editDish(scope.row))
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("编辑")
              ])),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_el_button, {
              size: "small",
              type: "danger",
              onClick: ($event: any) => (confirmDelete(scope.row.dishID))
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("删除")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createVNode(_component_el_dialog, {
      modelValue: dialogVisible.value,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((dialogVisible).value = $event)),
      title: "编辑菜品",
      "close-on-click-modal": false
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_el_button, {
          onClick: _cache[4] || (_cache[4] = ($event: any) => (dialogVisible.value = false))
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [
            _createTextVNode("取 消")
          ])),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: updateDish
        }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createTextVNode("确 定")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: currentDish.value,
          ref: "editForm",
          style: {"margin-left":"5%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "头像:",
              prop: "imageURL"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_el_upload, {
                    class: "upload-demo",
                    action: "https://connect.yzbhgzm.cn:9443/api/upload/file",
                    data: { folder: 'dish' },
                    "on-success": handleUploadSuccess,
                    "show-file-list": false,
                    accept: "image/*"
                  }, {
                    default: _withCtx(() => [
                      (currentDish.value?.imageURL)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: currentDish.value.imageURL,
                            alt: "头像",
                            class: "avatar-image1"
                          }, null, 8, _hoisted_8))
                        : _createCommentVNode("", true),
                      (!currentDish.value?.imageURL)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[10] || (_cache[10] = [
                            _createElementVNode("span", null, "上传头像", -1)
                          ])))
                        : _createCommentVNode("", true),
                      (currentDish.value?.imageURL)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[11] || (_cache[11] = [
                            _createElementVNode("span", null, "修改图片", -1)
                          ])))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "名称:",
              prop: "dishName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: currentDish.value.dishName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((currentDish.value.dishName) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "描述:",
              prop: "description"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: currentDish.value.description,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((currentDish.value.description) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "分类:",
              prop: "category"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: currentDish.value.category,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((currentDish.value.category) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})