import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"20px"} }
const _hoisted_2 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_3 = { style: {"margin-left":"10px"} }
const _hoisted_4 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_5 = { style: {"margin-left":"10px"} }
const _hoisted_6 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_7 = { style: {"display":"flex","align-items":"center"} }

import { ref, onMounted, computed } from "vue";
import api from "@/api"; // 导入封装的 API 实例
import { ElMessage, ElMessageBox } from "element-plus";
import { Timer, Search } from "@element-plus/icons-vue";
interface Creator {
    id: number;
    email: string | null;
    phone: string | null;
}

interface Announcement {
    id: number;
    title: string;
    content: string;
    status: string;
    creator: Creator;
    createdAt: string;
    updatedAt: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'announcement',
  setup(__props) {

const announcements = ref<Announcement[]>([]);
const editDialogVisible = ref(false); // 控制对话框的显示
const editForm = ref<Announcement>({
    id: 0,
    title: "",
    content: "",
    status: "",
    creator: { id: 0, email: null, phone: null },
    createdAt: "",
    updatedAt: "",
});
const searchQuery = ref(""); // 搜索内容
const filteredAnnouncements = ref<Announcement[]>([]); // 过滤后的公告列表

// 获取公告数据
const fetchAnnouncements = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        const response = await api.get("/API/announcements", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        announcements.value = response.data || [];
        filteredAnnouncements.value = announcements.value; // 初始化过滤后的公告列表
    } catch (error) {
        console.error("获取公告信息失败", error);
    }
};

// 搜索公告
const searchAnnouncements = () => {
    if (searchQuery.value.trim() === "") {
        filteredAnnouncements.value = announcements.value; // 如果搜索框为空，显示所有公告
    } else {
        filteredAnnouncements.value = announcements.value.filter(
            (announcement) => {
                return (
                    announcement.id.toString().includes(searchQuery.value) ||
                    announcement.title.includes(searchQuery.value)
                );
            }
        );
    }
};

// 格式化日期
const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString(); // 根据需要格式化日期
};

// 打开编辑对话框并填充数据
const openEditDialog = (announcement: Announcement) => {
    editForm.value = { ...announcement }; // 复制公告信息到编辑表单
    editDialogVisible.value = true; // 显示对话框
};

// 更新公告信息
const updateAnnouncement = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        await api.put(
            `/API/announcements/${editForm.value.id}`,
            editForm.value,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        ElMessage.success("公告更新成功");
        editDialogVisible.value = false; // 关闭对话框
        await fetchAnnouncements(); // 重新获取公告数据
    } catch (error) {
        ElMessage.error("更新公告失败");
        console.error("更新公告失败:", error);
    }
};

// 更新状态
const updateStatus = async (id: number, newStatus: string) => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        await api.put(
            `/API/announcements/${id}`,
            { status: newStatus },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        ElMessage.success("状态更新成功");
        await fetchAnnouncements(); // 刷新公告列表
    } catch (error) {
        ElMessage.error("状态更新失败");
        console.error("状态更新失败:", error);
    }
};

// 确认删除公告
const confirmDelete = (id: number) => {
    ElMessageBox.confirm("您确定要删除此公告吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
    })
        .then(() => {
            handleDelete(id);
        })
        .catch(() => {
            ElMessage.info("删除已取消");
        });
};

// 删除公告
const handleDelete = async (id: number) => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        await api.delete(`/API/announcements/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        ElMessage.success("公告删除成功");
        await fetchAnnouncements(); // 刷新公告列表
    } catch (error) {
        console.error("删除公告失败", error);
        ElMessage.error("删除公告失败");
    }
};

// 组件挂载时获取公告数据
onMounted(fetchAnnouncements);

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_input, {
        modelValue: searchQuery.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
        placeholder: "输入公告ID或标题搜索",
        style: {"width":"300px","margin-right":"10px"}
      }, null, 8, ["modelValue"]),
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: searchAnnouncements,
        icon: _unref(Search)
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode("搜索")
        ])),
        _: 1
      }, 8, ["icon"])
    ]),
    _createVNode(_component_el_table, {
      data: filteredAnnouncements.value,
      border: "",
      style: {"width":"100%"},
      "header-cell-style": { backgroundColor: '#f2f2f2', color: '#333' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: "公告ID",
          width: "180"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(scope.row.id), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "公告标题",
          width: "634"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", null, "《 " + _toDisplayString(scope.row.title) + " 》", 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "状态",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_switch, {
              modelValue: scope.row.status,
              "onUpdate:modelValue": ($event: any) => ((scope.row.status) = $event),
              "active-value": 'show',
              "inactive-value": 'hide',
              onChange: (newStatus) => updateStatus(scope.row.id, newStatus),
              style: {"--el-switch-on-color":"#13ce66","--el-switch-off-color":"#ff4949"}
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]),
            _createElementVNode("span", _hoisted_5, _toDisplayString(scope.row.status === "show" ? "显示" : "隐藏"), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "创建时间",
          width: "220"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_unref(Timer))
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(formatDate(scope.row.createdAt)), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "创建用户",
          width: "180"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(scope.row.creator.nickname), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "操作",
          width: "300"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_button, {
              size: "small",
              onClick: ($event: any) => (openEditDialog(scope.row))
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("编辑")
              ])),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_el_button, {
              size: "small",
              type: "danger",
              onClick: ($event: any) => (confirmDelete(scope.row.id))
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("删除")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createVNode(_component_el_dialog, {
      title: "编辑公告",
      modelValue: editDialogVisible.value,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((editDialogVisible).value = $event)),
      width: "30%"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_el_button, {
          onClick: _cache[4] || (_cache[4] = ($event: any) => (editDialogVisible.value = false))
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("取 消")
          ])),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: updateAnnouncement
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode("确 定")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: editForm.value,
          ref: "editFormRef",
          "label-width": "100px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "公告标题",
              prop: "title",
              rules: [
                        {
                            required: true,
                            message: '请输入公告标题',
                            trigger: 'blur',
                        },
                    ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: editForm.value.title,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((editForm.value.title) = $event)),
                  placeholder: "请输入公告标题"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "公告内容",
              prop: "content",
              rules: [
                        {
                            required: true,
                            message: '请输入公告内容',
                            trigger: 'blur',
                        },
                    ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: editForm.value.content,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((editForm.value.content) = $event)),
                  placeholder: "请输入公告内容",
                  rows: 4
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "状态",
              prop: "status",
              rules: [
                        {
                            required: true,
                            message: '请选择状态',
                            trigger: 'change',
                        },
                    ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: editForm.value.status,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((editForm.value.status) = $event)),
                  placeholder: "请选择状态"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "显示",
                      value: "show"
                    }),
                    _createVNode(_component_el_option, {
                      label: "隐藏",
                      value: "hide"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})