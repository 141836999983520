<template>
    <component :is="currentComponent" />
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import Layout from "./components/Layout.vue";
import UserLogin from "./components/UserLogin.vue";
import { useRoute } from "vue-router";

export default defineComponent({
    components: {
        Layout,
        UserLogin,
    },
    setup() {
        const route = useRoute();
        const currentComponent = computed(() => {
            // 根据当前路由判断渲染的组件
            return route.path === "/UserLogin" ? UserLogin : Layout;
        });
        return { currentComponent };
    },
});
</script>

<style></style>
