vue
<template>
    <div>
        <h1>添加公告</h1>
        <el-form
            :model="announcementForm"
            ref="announcementFormRef"
            label-width="100px"
        >
            <el-form-item
                label="公告标题"
                prop="title"
                :rules="[
                    {
                        required: true,
                        message: '请输入公告标题',
                        trigger: 'blur',
                    },
                ]"
            >
                <el-input
                    v-model="announcementForm.title"
                    placeholder="请输入公告标题"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="公告内容"
                prop="content"
                :rules="[
                    {
                        required: true,
                        message: '请输入公告内容',
                        trigger: 'blur',
                    },
                ]"
            >
                <el-input
                    type="textarea"
                    v-model="announcementForm.content"
                    placeholder="请输入公告内容"
                    :rows="4"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="状态"
                prop="status"
                :rules="[
                    {
                        required: true,
                        message: '请选择状态',
                        trigger: 'change',
                    },
                ]"
            >
                <el-select
                    v-model="announcementForm.status"
                    placeholder="请选择状态"
                >
                    <el-option label="显示" value="show"></el-option>
                    <el-option label="隐藏" value="hide"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="发布人" prop="creator">
                <el-input v-model="currentUser.nickname" disabled></el-input>
                <input type="hidden" v-model="announcementForm.creatorId" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitAnnouncement"
                    >提交</el-button
                >
                <el-button @click="resetForm">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import api from "@/api"; // 导入封装的 API 实例
import { ElMessage } from "element-plus";

interface AnnouncementForm {
    title: string;
    content: string;
    status: string; // 状态
    creatorId: number; // 发布人ID
}

const announcementForm = ref<AnnouncementForm>({
    title: "",
    content: "",
    status: "show", // 默认状态
    creatorId: 0, // 初始化为0
});

const currentUser = ref<{ id: number; nickname: string }>({
    id: 0,
    nickname: "",
}); // 当前用户信息

// 获取当前用户信息
const fetchCurrentUser = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        const response = await api.get("/API/userInfo", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        currentUser.value = response.data.userInfo; // 假设返回的数据包含用户的 ID 和昵称
        announcementForm.value.creatorId = currentUser.value.id; // 设置发布人ID
    } catch (error: any) {
        // 将 error 指定为 any 类型
        console.error("获取用户信息失败", error);
        ElMessage.error("获取用户信息失败");
    }
};

// 提交公告
const submitAnnouncement = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        ElMessage.error("未找到 token");
        return;
    }
    try {
        await api.post(
            "/API/add/announcements",
            {
                title: announcementForm.value.title,
                content: announcementForm.value.content,
                status: announcementForm.value.status,
                creator: {
                    id: announcementForm.value.creatorId, // 发送用户ID
                },
                createdAt: new Date().toISOString(), // 设置创建时间
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        ElMessage.success("公告添加成功");
        resetForm(); // 重置表单
    } catch (error: any) {
        // 将 error 指定为 any 类型
        console.error(
            "添加公告失败",
            error.response ? error.response.data : error
        );
        ElMessage.error(
            "添加公告失败: " +
                (error.response ? error.response.data.message : "未知错误")
        );
    }
};

// 重置表单
const resetForm = () => {
    announcementForm.value = {
        title: "",
        content: "",
        status: "show", // 默认状态
        creatorId: currentUser.value.id, // 保持发布人ID不变
    };
};

// 组件挂载时获取用户信息
onMounted(fetchCurrentUser);
</script>

<style scoped>
/* 可以添加您自己的样式 */
h1 {
    text-align: center;
    margin-bottom: 20px;
}
</style>
