<template>
    <el-form
        ref="registerFormRef"
        :model="registerForm"
        :rules="rules"
        status-icon
        label-width="auto"
        class="register-form"
    >
        <el-form-item label="用户名" prop="username">
            <el-input
                v-model="registerForm.username"
                placeholder="请输入用户名"
            />
        </el-form-item>
        <el-form-item label="密码" prop="password">
            <el-input
                v-model="registerForm.password"
                type="password"
                autocomplete="off"
                placeholder="请输入密码"
            />
        </el-form-item>
        <el-form-item label="重复密码" prop="confirmPassword">
            <el-input
                v-model="registerForm.confirmPassword"
                type="password"
                autocomplete="off"
                placeholder="请确认密码"
            />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
            <el-input v-model="registerForm.phone" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
            <el-input v-model="registerForm.email" placeholder="请输入邮箱" />
        </el-form-item>
        <el-form-item>
            <div class="button-group">
                <el-button type="primary" @click="submitForm">注册</el-button>
                <el-button @click="resetForm">重置</el-button>
            </div>
        </el-form-item>
    </el-form>
</template>

<script lang="ts" setup>
import { reactive, ref } from "vue";
import api from "@/api"; // 导入封装的 API 实例
import { ElMessage } from "element-plus";

const registerFormRef = ref();
const registerForm = reactive({
    username: "",
    password: "",
    confirmPassword: "",
    phone: "",
    email: "",
});

const validateConfirmPassword = (
    rule: any,
    value: string,
    callback: (error?: string | Error) => void
) => {
    if (value === "") {
        callback(new Error("请确认密码"));
    } else if (value !== registerForm.password) {
        callback(new Error("两次输入的密码不一致"));
    } else {
        callback();
    }
};

const rules = {
    username: [
        { required: true, message: "用户名不能为空", trigger: "blur" },
        { min: 2, message: "用户名不能少于2个字符", trigger: "blur" },
    ],
    password: [
        { required: true, message: "密码不能为空", trigger: "blur" },
        {
            min: 0,
            max: 16,
            message: "密码长度应在0到16个字符之间",
            trigger: "blur",
        },
    ],
    confirmPassword: [
        { required: true, message: "请确认密码", trigger: "blur" },
        { validator: validateConfirmPassword, trigger: "blur" },
    ],
    phone: [
        { required: true, message: "手机号不能为空", trigger: "blur" },
        {
            pattern: /^[1][3-9][0-9]{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
        },
    ],
    email: [
        { required: true, message: "邮箱不能为空", trigger: "blur" },
        { type: "email", message: "邮箱格式不正确", trigger: "blur" },
    ],
};

const submitForm = async () => {
    try {
        await registerFormRef.value?.validate(async (valid: boolean) => {
            if (valid) {
                const url = "/API/register"; // 使用相对路径
                const data = {
                    username: registerForm.username,
                    password: registerForm.password,
                    email: registerForm.email,
                    phone: registerForm.phone,
                };
                try {
                    const response = await api.post(url, data); // 使用封装的 API 实例
                    if (response.status === 200) {
                        ElMessage({ message: "注册成功", type: "success" });
                    } else if (response.status === 409) {
                        ElMessage({ message: "用户名已存在", type: "error" });
                    } else {
                        ElMessage({
                            message:
                                "注册失败: " +
                                (response.data.message || "未知错误"),
                            type: "error",
                        });
                    }
                } catch (error: any) {
                    ElMessage({
                        message:
                            "注册失败: " +
                            (error.response
                                ? error.response.data
                                : "网络请求失败"),
                        type: "error",
                    });
                }
            } else {
                console.log("注册失败");
            }
        });
    } catch (error) {
        console.error("请求错误", error);
    }
};

const resetForm = () => {
    registerFormRef.value?.resetFields();
};
</script>

<style scoped>
.register-form {
    max-width: 400px; /* 适当调整宽度 */
    padding: 20px;
}
.register-form .el-form-item {
    margin-bottom: 15px;
}
.register-form .el-button {
    width: 100%;
}
.button-group {
    display: flex;
    justify-content: space-between; /* 按钮之间的间距 */
    margin-left: 30px;
}
.button-group .el-button {
    width: 48%; /* 使按钮宽度相等并留出间距 */
    margin-right: 180%; /* 为右侧按钮添加右边距 */
    margin-top: 20px;
}
.button-group .el-button:last-child {
    margin-right: 0; /* 最后一个按钮去掉右边距 */
}
</style>
